import React, {useState} from 'react';
import styles from './index.module.less';
import Module from '../../../comp-mobile/module';
import Footer from '../../../comp-mobile/footer';
import PageTop_M from '../../../comp-mobile/pageTop';
import Header from '../../../comp-mobile/header';
import {dutyList} from '../../../contant';

const Join_M = (props) => {
    document.title = '加入我们'
    const [current, setCurrent] = useState(0);
    return <div className={styles.container_wrapper}>
        <Header/>
        <PageTop_M bg={'/img/join02.png'}/>
        <div className={styles.content_wrapper}>
            <Module title={'社会招聘'} eng={'Social Recruitment'}>
                <div className={styles.tab_wrapper}>
                    {
                        dutyList.map((ele, index) => {
                            return <div onClick={() => {setCurrent(index);}}
                                        key={index}
                                        className={`${styles.tab_item} ${current ===
                                        index
                                            ? styles.selected
                                            : ''}`}>{ele.title}</div>;
                        })
                    }
                </div>
                <div className={styles.duty_wrapper}>
                    <div
                        className={styles.title}>{dutyList[current].title}</div>
                    <div className={styles.duty_title}>岗位职责：</div>
                    <div className={styles.duty_content}>
                        {dutyList[current].content}
                    </div>

                    <div className={styles.duty_title}>任职要求：</div>
                    <div className={styles.duty_content}>
                        {dutyList[current].duty}
                    </div>
                    <div className={styles.remark_title}>联系我们：</div>
                    <div className={styles.remark}>
                        {'请发送个人简历及个人作品至cuimin@toplion.com.cn'}<br/>
                        {'注明申请职位和入职时间，我们会在一周内回复。'}<br/>
                        {'若有疑问，可拨打电话17669495253详询。'}
                    </div>
                </div>
            </Module>

            <Footer/>
        </div>
    </div>;
};
export default Join_M;
