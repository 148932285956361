import React,{useEffect} from 'react'
import styles from './basicLayout.moudle.less'

const BasicLayout = (props)=>{
    useEffect(()=>{
        document.body.scrollTop=document.documentElement.scrollTop=0
        return ()=>{

        }
    },[])
    return <div className={styles.container} >
        {props.children}
    </div>
}

export default BasicLayout
