import React, {
    Component,
    createRef,
    useEffect,
    useState,
    useReducer,
} from 'react';
import styles from './detail.module.less';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.less';
import 'swiper/components/navigation/navigation.less';
import 'swiper/components/pagination/pagination.less';
import 'swiper/components/scrollbar/scrollbar.less';
import Module from '../../../comp-mobile/module';
import Footer from '../../../comp-mobile/footer';
import PageTop_M from '../../../comp-mobile/pageTop';
import Header from '../../../comp-mobile/header';
import SwiperCore, {
    A11y,
    Navigation,
    Pagination,
    Scrollbar,
    Mousewheel,
    Virtual,
} from 'swiper';
import ScrollReveal from 'scrollreveal';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Mousewheel, Virtual]);

const innerHeight = document.body.clientHeight - 91;

class PlatformDetail extends Component {
    swiperRef = React.createRef();
    swiperObj = null;

    state = {
        clientHeight: innerHeight < 600 ? 600 : innerHeight,
        current: 0,
    };

    componentWillReceiveProps(nextProps, nextContext) {
        const index = parseInt(nextProps.match.params.type);
        // window.scrollTo({
        //   top: index * this.state.clientHeight,
        //   behavior: 'smooth'
        // })
        // this.swiperObj.slideTo(index,0,false)
        this.setState({
            current: index,
        });
    }

    componentDidMount() {
        const index = this.props.match.params.type;
        document.title = '平台及服务';
        setTimeout(() => {
            if (index) {
                let anchorElement = document.getElementById(index);
                if (anchorElement) { anchorElement.scrollIntoView(); }
            }
        },50);

    }

    yunzhiTransition = () => {
        let pc = {
            distance: '200px',
            origin: 'left',
            reset: true,
            delay: 100,
        };
        ScrollReveal().reveal(`.s_pc`, pc);

        let yunzhi1 = {
            distance: '500px',
            origin: 'right',
            reset: true,
            delay: 100,
        };
        let yunzhi2 = {
            ...yunzhi1,
            delay: 300,
        };
        let yunzhi3 = {
            ...yunzhi1,
            delay: 500,
        };
        ScrollReveal().reveal(`.yunzhi1`, yunzhi1);
        ScrollReveal().reveal(`.yunzhi2`, yunzhi2);
        ScrollReveal().reveal(`.yunzhi3`, yunzhi3);

    };

    yuncaiTransition = () => {
        let yuncai1 = {
            distance: '200px',
            origin: 'top',
            reset: true,
            delay: 100,
        };
        ScrollReveal().reveal(`.yuncai1`, yuncai1);

        let yuncai2 = {
            distance: '200px',
            origin: 'bottom',
            reset: true,
            delay: 100,
        };
        let yuncai3 = {
            ...yuncai2,
            delay: 300,
        };
        ScrollReveal().reveal(`.yuncai1`, yuncai1);
        ScrollReveal().reveal(`.yuncai2`, yuncai2);
        ScrollReveal().reveal(`.yuncai3`, yuncai3);

    };

    yunshuTransition = () => {
        let yunshu1 = {
            distance: '200px',
            origin: 'left',
            reset: true,
            delay: 100,
        };
        ScrollReveal().reveal(`.yunshu1`, yunshu1);

        let yunshu2 = {
            distance: '200px',
            origin: 'right',
            reset: true,
            delay: 100,
        };
        ScrollReveal().reveal(`.yunshu1`, yunshu1);
        ScrollReveal().reveal(`.yunshu2`, yunshu2);
        ScrollReveal().reveal(`.yunshu3`, {...yunshu2, delay: 200});
        ScrollReveal().reveal(`.yunshu4`, {...yunshu2, delay: 300});
        ScrollReveal().reveal(`.yunshu5`, {...yunshu2, delay: 400});
        ScrollReveal().reveal(`.yunshu6`, {...yunshu2, delay: 500});
        ScrollReveal().reveal(`.yunshu7`, {...yunshu2, delay: 200});
        ScrollReveal().reveal(`.yunshu8`, {...yunshu2, delay: 300});
        ScrollReveal().reveal(`.yunshu9`, {...yunshu2, delay: 400});
        ScrollReveal().reveal(`.yunshu10`, {...yunshu2, delay: 500});

    };

    yunwuTransition = () => {
        let yunwu1 = {
            distance: '200px',
            origin: 'left',
            reset: true,
            delay: 100,
        };
        ScrollReveal().reveal(`.yunwu1`, yunwu1);

        let yunwu2 = {
            distance: '200px',
            origin: 'right',
            reset: true,
            delay: 100,
        };
        ScrollReveal().reveal(`.yunwu1`, yunwu1);
        ScrollReveal().reveal(`.yunwu2`, {...yunwu1, delay: 200});
        ScrollReveal().reveal(`.yunwu3`, {...yunwu1, delay: 300});
        ScrollReveal().reveal(`.yunwu4`, yunwu2);

    };

    render() {
        const renderTitle = ({eng, title, desc, white}) => {
            return <div className={white
                ? styles.slider_title_white
                : styles.slider_title}>
                <div>{title}</div>
                <div className={styles.eng}>
                    {desc}
                </div>
            </div>;
        };

        const yunshuData = [
            {
                img: '/img/shujufenxi.png',
                title: '数据分析',
                desc: '基于多种分析引擎进行多维度数据 分析,为数据分析、数据决策提供 稳定支撑。',
            }, {
                img: '/img/shujuwajue.png',
                title: '数据挖掘',
                desc: '实现数据从创建到消亡全生命周期 的管理,实现数据价值的挖掘和应 用。',
            }, {
                img: '/img/jiqixuexi.png',
                title: '机器学习',
                desc: '强大的数据采集能力,可通过数据 传输产品完成丰富数据源之间的互 相传输；支持场景数据源的流控。',
            }, {
                img: '/img/fengzhuangfuneng.png',
                title: '封装赋能',
                desc: '支持深度学习等多种算法模型训练 能力,赋能多场景智能应用,包括 预测、预警、个性化推荐等。',
            }];
        return <div className={styles.container_wrapper}>
            <Header/>
            <div className={styles.swiper_wrapper}>
                <div className={styles.slider_container} id={'0'}>
                    <img className={styles.bg} src={'/img/yunzhi_bg.png'}/>
                    {renderTitle({
                        title: '云智数据智能管理平台',
                        eng: 'yunzhi',
                        desc: '智能决策、智能客服、智能工单',
                        white: true,
                    })}
                    <div className={styles.slider_content}>
                        <div className={styles.yunzhi_wrapper}>
                            <img src={'/img/yunzhi_img.png'}/>
                            <div className={styles.intro_wrapper}>
                                <div className={styles.img_title_wrapper}>
                                    <img className={styles.icon}
                                         src={'/img/yunzhi1.png'}/>
                                    <div className={styles.title_wrapper}>
                                        <div className={styles.title_w}>智能决策
                                        </div>
                                        <div
                                            className={styles.desc}>提供业务管理、运营等一站式服务,打造
                                            数据化、精细化运营管理模式,利用数据 智能,助力项目降本增收。
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.img_title_wrapper}>
                                    <img className={styles.icon}
                                         src={'/img/yunzhi2.png'}/>
                                    <div className={styles.title_wrapper}>
                                        <div className={styles.title_w}>智能客服
                                        </div>
                                        <div
                                            className={styles.desc}>利用用户画像、热点分析、业务流程质检
                                            、人机交互等核心能力,建立“AI+客服”, 实现客服智能化。
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.img_title_wrapper}>
                                    <img className={styles.icon}
                                         src={'/img/yunzhi3.png'}/>
                                    <div className={styles.title_wrapper}>
                                        <div className={styles.title_w}>智能工单
                                        </div>
                                        <div
                                            className={styles.desc}>利用全覆盖+自学习的智能工单系统,辅助
                                            人工工单处理经验,建立提高支撑满意度 的运维工具。
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.slider_container} id={'1'}>
                    <img className={styles.bg} src={'/img/yuncai_bg.png'}/>
                    {renderTitle({
                        title: '云彩应用管理系统',
                        eng: 'yuncai',
                        desc: '运营端小程序,消费端小程序、第三方系统植入等',
                    })}
                    <div className={styles.yuncai_wrapper}>
                        <img src={'/img/mobile/m_yuncai1.png'}
                             className={styles.img}/>

                        <div className={styles.app_wrapper}>
                            <div>消费端：消费,充值,绑卡</div>
                            <div>运营端：查看数据,处理工单,设备巡检</div>
                        </div>

                        <img src={'/img/mobile/m_yuncai2.png'}
                             className={styles.img}/>

                        <div className={styles.app_wrapper}>
                            专业技术团队支持，独立品牌设计，独立用户入口
                        </div>
                    </div>
                </div>

                <div className={styles.slider_container} id={'2'}>
                    <img className={styles.bg} src={'/img/yunshu_bg.png'}/>
                    {renderTitle({
                        title: '云数数据管理平台',
                        eng: 'yunshu',
                        desc: '数据仓储及数据建模分析服务',
                        white: true,
                    })}
                    <div className={styles.slider_content}>
                        <img src={'/img/mobile/m_yunshu1.png'}
                             className={styles.img}/>
                        <div className={styles.yunshu_wrapper}>
                            {yunshuData.map((ele, index) => {
                                return <div
                                    className={styles.yunshu_item_wrapper}>
                                    <strong>
                                        <img src={ele.img}
                                             className={styles.icon}/>
                                        {ele.title}
                                    </strong>
                                    <div style={{marginTop: '10px'}}>
                                        {ele.desc}
                                    </div>
                                </div>;
                            })}
                        </div>
                    </div>
                </div>

                <div className={styles.slider_container} id={'3'}>
                    {renderTitle({
                        title: '云物校园生活服务管理系统',
                        eng: 'yunwu',
                        desc: '校园生活运营项目一站式服务',
                    })}
                    <img className={styles.bg} src={'/img/yuncai_bg.png'}/>
                    <div className={styles.slider_content}>
                        <img src={'/img/yunwu_right.png'}
                             className={styles.img}/>
                        <img src={'/img/zichanjizhong.png'}
                             style={{marginBottom: '20px'}}
                             className={`${styles.img} desc1`}/>
                        <img src={'/img/shujujizhong.png'}
                             style={{marginBottom: '20px'}}
                             className={`${styles.img} desc2`}/>
                        <img src={'/img/guanlijizhong.png'}
                             className={`${styles.img} desc3`}/>
                    </div>
                </div>


                <div className={styles.slider_container} id={'4'}>
                    {renderTitle({
                        title: '云网校园网络服务管理系统',
                        eng: 'yunwang',
                        desc: '校园网络运营项目全周期服务',
                        white: true,
                    })}
                    <img className={styles.bg} src={'/img/yunwang_bg.png'}/>
                    <div className={styles.slider_content}>
                        <img src={'/img/mobile/m_yunwang1.png'}
                             className={styles.img}/>
                    </div>
                </div>


                <div className={styles.slider_container} id={'5'}>
                    {renderTitle({
                        title: '校园后勤管理系统',
                        eng: 'houqin',
                        desc: '人事、财务、学工、科研管理',
                    })}
                    <img className={styles.bg} src={'/img/houqin_bg.png'}/>
                    <div className={styles.slider_content}>
                        <img className={styles.img}
                             src={'/img/houqin_img.png'}/>
                    </div>
                </div>


                <div className={styles.slider_container} id={'6'}>
                    <img className={styles.bg} src={'/img/yingxin_bg.png'}/>
                    {renderTitle({
                        title: '迎新宿管系统',
                        eng: 'yingxin',
                        desc: '身份认识及宿舍管理',
                        white: true,
                    })}
                    <img className={styles.bg} src={'/img/yingxin_bg.png'}/>*/}

                    <div className={styles.slider_content}>
                        <img className={styles.img}
                             src={'/img/mobile/m_suguan.png'}/>
                    </div>
                </div>


                <div className={styles.slider_container} id={'7'}>
                    {renderTitle({
                        title: '平安校园管理预警系统',
                        eng: 'pingan',
                        desc: '自主研发的校园预警平台，通过自身的努力，保障学生的身心安全，协助学校共同打造智慧且安全的校园环境。',
                    })}
                    <img className={styles.bg} src={'/img/pingan_bg.png'}/>
                    <div className={styles.slider_content}>
                        <img className={styles.img} src={'/img/pingan1.png'}/>
                        <div className={styles.pingan_wrapper}>
                            <div className={styles.info_item}>
                                <div className={styles.title}>1、预警信息</div>
                                <div
                                    className={styles.desc}>预警信息包括疑似校园贷、思想政治、精神类、疾病类等。以思想政治预警
                                    为例，学生在登录后上网过程中，使用搜索引擎或者网页推送内容，涉及到
                                    了如宗教、邪教等敏感关键词，系统将自动记录该搜索和浏览信息，并在后
                                    台记录，记录内容包括：该学生的学号、姓名、院系班级、关联辅导员、电
                                    话。信息经过加密脱敏后，只有特定权限的管理者才会看到完整的学生信息，
                                    保护学生隐私。有效的避免宗教势力向校园环境的渗透。
                                </div>
                            </div>

                            <div className={styles.info_item}>
                                <div className={styles.title}>2、辅助指导类信息</div>
                                <div
                                    className={styles.desc}>系统对搜索引擎重点关注，将学生在日常上网中使用搜索引擎输入的
                                    关键词和查询结果进行重点统计，并将搜索关键词进行了统计、分类
                                    和排行，方便学校管理者对学生上网倾向进行分析了解，比如创业类 信息与知识资源等。
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className={styles.slider_container} id={'8'}>
                    {renderTitle({
                        title: '云通统一支付系统',
                        eng: 'yuntong',
                        desc: '统一收单服务',
                        white: true,
                    })}*/}
                    <img className={styles.bg} src={'/img/yuntong_bg.png'}/>
                    <div className={styles.slider_content}>
                        <img className={styles.img}
                             src={'/img/mobile/m_yuntong1.png'}/>
                        <img className={styles.img} style={{marginTop: '20px'}}
                             src={'/img/mobile/m_yuntong2.png'}/>
                    </div>
                </div>


                <div className={styles.slider_container} id={'9'}>
                    {renderTitle(
                        {title: '云芯卡管理系统', eng: 'yunxin', desc: '校园卡管理及服务'})}
                    <img className={styles.bg} src={'/img/yunxin_bg.png'}/>
                    <div className={styles.slider_content}>
                        <img style={{margin: '10px 0'}} className={styles.img}
                             src={'/img/mobile/m_yunxin1.png'}/>
                        <div className={styles.yunxin_wrapper}>

                            <div className={styles.yunxin_item_wrapper}>
                                <div className={styles.img_title_wrapper}>
                                    <img className={styles.icon}
                                         src={'/img/yunxin1.png'}/>
                                    <div className={styles.title_wrapper}>
                                        <div className={styles.title_b}>卡片管理
                                        </div>
                                        <div
                                            className={styles.desc_b}>卡片对接：通过系统配置,能够快速与学校业务
                                            进行对接；卡片管控：实现对卡片使用状态的管
                                            控,对卡片（用户）的关联业务进行管理。
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.yunxin_item_wrapper}>
                                <div className={styles.img_title_wrapper}>
                                    <img className={styles.icon}
                                         src={'/img/yunxin2.png'}/>
                                    <div className={styles.title_wrapper}>
                                        <div className={styles.title_b}>用户管理
                                        </div>
                                        <div
                                            className={styles.desc_b}>卡片对接：通过系统配置,能够快速与学校业务
                                            进行对接；卡片管控：实现对卡片使用状态的管
                                            控,对卡片（用户）的关联业务进行管理。
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.yunxin_item_wrapper}>
                                <div className={styles.img_title_wrapper}>
                                    <img className={styles.icon}
                                         src={'/img/yunxin3.png'}/>
                                    <div className={styles.title_wrapper}>
                                        <div className={styles.title_b}>身份认证
                                        </div>
                                        <div
                                            className={styles.desc_b}>将同一用户在多个平台的不同身份进行统一,建
                                            立统一的用户信息平台,实现统一身份管理。
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.yunxin_item_wrapper}>
                                <div className={styles.img_title_wrapper}>
                                    <img className={styles.icon}
                                         src={'/img/yunxin4.png'}/>
                                    <div className={styles.title_wrapper}>
                                        <div className={styles.title_b}>信用体系
                                        </div>
                                        <div
                                            className={styles.desc_b}>将同一用户在多个平台的不同身份进行统一,建
                                            立统一的用户信息平台,实现统一身份管理。
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className={styles.slider_container} id={'10'}>
                    {renderTitle({
                        title: '云合物联网网关系统',
                        eng: 'yunhe',
                        desc: '物联网设备接入及标准化服务',
                        white: true,
                    })}
                    <img className={styles.bg} src={'/img/yunhe_bg.png'}/>
                    <div className={styles.slider_content}>
                        <img style={{margin: '10px 0'}} className={styles.img} src={'/img/mobile/m_yunhe1.png'}/>
                        <div className={styles.yunxin_wrapper}>
                            <div className={styles.img_title_wrapper}>
                                <img className={styles.icon}
                                     src={'/img/yunhe1.png'}/>
                                <div className={styles.title_wrapper}>
                                    <div
                                        className={styles.title_w}>丰富的云端赋能
                                    </div>
                                    <div
                                        className={styles.desc}>通过聚合能力、服务,赋能给第三方平台
                                        及厂商形成共赢
                                    </div>
                                </div>
                            </div>
                            <div className={styles.img_title_wrapper}>
                                <img className={styles.icon}
                                     src={'/img/yunhe2.png'}/>
                                <div className={styles.title_wrapper}>
                                    <div
                                        className={styles.title_w}>开放的平台生态
                                    </div>
                                    <div
                                        className={styles.desc}>接入第三方应用开发者,为厂商及务商提
                                        供更多应用层面支持
                                    </div>
                                </div>
                            </div>
                            <div className={styles.img_title_wrapper}>
                                <img className={styles.icon}
                                     src={'/img/yunhe3.png'}/>
                                <div className={styles.title_wrapper}>
                                    <div
                                        className={styles.title_w}>全方位的云服务
                                    </div>
                                    <div
                                        className={styles.desc}>提供从设备接入、数据存储到应用系统整
                                        套云服务,省去您自己搭建平台的成本。
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<Swiper*/}
                {/*    direction='vertical'*/}
                {/*    ref={this.swiperRef}*/}
                {/*    // height={}*/}
                {/*    grabCursor*/}
                {/*    mousewheel={{ releaseOnEdges: true }}*/}
                {/*    // onSlideChange={onSlideChange}*/}
                {/*    pagination*/}
                {/*    // virtual*/}
                {/*    onSwiper={swiper => (this.swiperObj = swiper)}*/}
                {/*>*/}
                {/*  <SwiperSlide>*/}
                {/*    <div className={styles.slider_container}>*/}
                {/*      <img className={styles.bg} src={'/img/yunzhi_bg.png'}/>*/}
                {/*      {renderTitle({ title: '云智数据智能管理平台', eng: 'yunzhi', desc: '智能决策、智能客服、智能工单', white: true })}*/}
                {/*      <div className={styles.slider_content}>*/}
                {/*        <div className={styles.yunzhi_wrapper}>*/}
                {/*          <img src={'/img/yunzhi_img.png'}/>*/}
                {/*          <div className={styles.intro_wrapper}>*/}
                {/*            <div className={styles.img_title_wrapper}>*/}
                {/*              <img className={styles.icon} src={'/img/yunzhi1.png'}/>*/}
                {/*              <div className={styles.title_wrapper}>*/}
                {/*                <div className={styles.title_w}>智能决策</div>*/}
                {/*                <div className={styles.desc}>提供业务管理、运营等一站式服务,打造 数据化、精细化运营管理模式,利用数据 智能,助力项目降本增收。</div>*/}
                {/*              </div>*/}
                {/*            </div>*/}
                {/*            <div className={styles.img_title_wrapper}>*/}
                {/*              <img className={styles.icon} src={'/img/yunzhi2.png'}/>*/}
                {/*              <div className={styles.title_wrapper}>*/}
                {/*                <div className={styles.title_w}>智能客服</div>*/}
                {/*                <div className={styles.desc}>利用用户画像、热点分析、业务流程质检 、人机交互等核心能力,建立“AI+客服”, 实现客服智能化。</div>*/}
                {/*              </div>*/}
                {/*            </div>*/}
                {/*            <div className={styles.img_title_wrapper}>*/}
                {/*              <img className={styles.icon} src={'/img/yunzhi3.png'}/>*/}
                {/*              <div className={styles.title_wrapper}>*/}
                {/*                <div className={styles.title_w}>智能工单</div>*/}
                {/*                <div className={styles.desc}>利用全覆盖+自学习的智能工单系统,辅助 人工工单处理经验,建立提高支撑满意度 的运维工具。</div>*/}
                {/*              </div>*/}
                {/*            </div>*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </SwiperSlide>*/}

                {/*  <SwiperSlide>*/}
                {/*    <div className={styles.slider_container}>*/}
                {/*      <img className={styles.bg} src={'/img/yuncai_bg.png'}/>*/}
                {/*      {renderTitle({ title: '云彩应用管理系统', eng: 'yuncai', desc: '运营端小程序,消费端小程序、第三方系统植入等' })}*/}
                {/*      <div className={styles.slider_content}>*/}
                {/*        <div className={styles.yuncai_wrapper}>*/}
                {/*          <div className={styles.img_wrapper}>*/}
                {/*            <img src={'/img/yuncai_top.png'} className={styles.img_wrapper}/>*/}

                {/*            <div className={styles.app_wrapper}>*/}
                {/*              <div>消费端：消费,充值,绑卡</div>*/}
                {/*              <div>运营端：查看数据,处理工单,设备巡检</div>*/}
                {/*            </div>*/}
                {/*            <div className={styles.oem_wrapper}>*/}
                {/*              <div>专业技术团队支持</div>*/}
                {/*              <div>独立品牌设计</div>*/}
                {/*              <div>独立用户入口</div>*/}
                {/*            </div>*/}
                {/*          </div>*/}
                {/*          /!*<div className={styles.mp_wrapper}>*!/*/}

                {/*          /!*</div>*!/*/}
                {/*          /!*<div className={styles.oem_wrapper}>*!/*/}

                {/*          /!*</div>*!/*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </SwiperSlide>*/}
                {/*  */}
                {/*  */}

                {/*  <SwiperSlide>*/}
                {/*    <div className={styles.slider_container}>*/}
                {/*      <img className={styles.bg} src={'/img/yunshu_bg.png'}/>*/}
                {/*      {renderTitle({ title: '云数数据管理平台', eng: 'yunshu', desc: '数据仓储及数据建模分析服务', white: true })}*/}
                {/*      <div className={styles.slider_content}>*/}
                {/*        <div className={styles.yunshu_wrapper}>*/}
                {/*          <div className={styles.left_wrapper}>*/}
                {/*            <img src={'/img/yunshu_left.png'}/>*/}
                {/*            <div className={styles.intro_wrapper}>*/}
                {/*              {yunshuLevel.map((ele, index) => {*/}
                {/*                return <div key={index} className={styles.intro_item}>*/}
                {/*                  <div className={styles.level}>{ele.title}</div>*/}
                {/*                  <div className={styles.level_info}>{ele.desc}</div>*/}
                {/*                </div>*/}
                {/*              })}*/}
                {/*            </div>*/}
                {/*          </div>*/}
                {/*          <div className={styles.right_wrapper}>*/}
                {/*            {yunshuData.map((ele, index) => {*/}
                {/*              return <div className={styles.right_item}>*/}
                {/*                <div className={styles.right_title}>*/}
                {/*                  <img src={ele.img}/>*/}
                {/*                  {ele.title}*/}
                {/*                </div>*/}
                {/*                <div className={styles.right_desc}>*/}
                {/*                  {ele.desc}*/}
                {/*                </div>*/}
                {/*              </div>*/}
                {/*            })}*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </SwiperSlide>*/}


                {/*  <SwiperSlide>*/}
                {/*    <div className={styles.slider_container}>*/}
                {/*      {renderTitle({ title: '云物校园生活服务管理系统', eng: 'yunwu', desc: '数据仓储及数据建模分析服务' })}*/}
                {/*      <img className={styles.bg} src={'/img/yuncai_bg.png'}/>*/}
                {/*      <div className={styles.slider_content}>*/}
                {/*        <div className={styles.yunzhi_wrapper}>*/}

                {/*          <div className={`${styles.intro_wrapper}`}>*/}
                {/*            <img src={'/img/zichanjizhong.png'} className={`${styles.intro_desc} desc1`}/>*/}
                {/*            <img src={'/img/shujujizhong.png'} className={`${styles.intro_desc} desc2`}/>*/}
                {/*            <img src={'/img/guanlijizhong.png'} className={`${styles.intro_desc} desc3`}/>*/}
                {/*          </div>*/}
                {/*          <div className={styles.img_wrapper}>*/}
                {/*            <img src={'/img/yunwu_right.png'}/>*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </SwiperSlide>*/}

                {/*  <SwiperSlide>*/}
                {/*    <div className={styles.slider_container}>*/}
                {/*      {renderTitle({ title: '云网校园网络服务管理系统', eng: 'yunwang', desc: 'ICT 项目管理', white: true })}*/}
                {/*      <img className={styles.bg} src={'/img/yunwang_bg.png'}/>*/}
                {/*      <div className={styles.slider_content}>*/}
                {/*        <div className={styles.yunwang_wrapper}>*/}

                {/*          <img src={'/img/yunwang1.png'} className={styles.wang_img}>*/}

                {/*          </img>*/}
                {/*          <div className={styles.desc_wrapper}>*/}
                {/*            <div className={styles.desc_item}>*/}
                {/*              <div className={styles.title}>平台支持</div>*/}
                {/*              <div className={styles.desc}>提供校园综合服务平台对接服务,省却代理商开发成本。</div>*/}
                {/*            </div>*/}
                {/*            <div className={styles.line}/>*/}
                {/*            <div className={styles.desc_item}>*/}
                {/*              <div className={styles.title}>产品支持</div>*/}
                {/*              <div className={styles.desc}>校园全系列成热产品,按需选择、提供。</div>*/}
                {/*            </div>*/}
                {/*            <div className={styles.line}/>*/}
                {/*            <div className={styles.desc_item}>*/}
                {/*              <div className={styles.title}>模式支持</div>*/}
                {/*              <div className={styles.desc}>顶联多年校园网盈利模式探索和运营经验输出。</div>*/}
                {/*            </div>*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </SwiperSlide>*/}

                {/*  <SwiperSlide>*/}
                {/*    <div className={styles.slider_container}>*/}
                {/*      {renderTitle({ title: '校园后勤管理系统', eng: 'houqin', desc: '人事、财务、学工、科研管理' })}*/}
                {/*      <img className={styles.bg} src={'/img/houqin_bg.png'}/>*/}
                {/*      <div className={styles.slider_content}>*/}
                {/*        <div className={styles.yunwang_wrapper}>*/}
                {/*          <img className={styles.wang_img} src={'/img/houqin_img.png'}/>*/}

                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </SwiperSlide>*/}

                {/*  <SwiperSlide>*/}
                {/*    <div className={styles.slider_container}>*/}
                {/*      <img className={styles.bg} src={'/img/yingxin_bg.png'}/>*/}
                {/*      {renderTitle({ title: '迎新宿管系统', eng: 'yingxin', desc: '身份认识及宿舍管理', white: true })}*/}
                {/*      <img className={styles.bg} src={'/img/yingxin_bg.png'}/>*!/*/}

                {/*      <div className={styles.slider_content}>*/}
                {/*        <div className={styles.yingxin_wrapper}>*/}
                {/*          <img style={{ width: '100%' }} className={styles.yingxin_img_wrapper} src={'/img/yingxin_w.png'}/>*/}

                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </SwiperSlide>*/}

                {/*  <SwiperSlide>*/}
                {/*    <div className={styles.slider_container}>*/}
                {/*      {renderTitle({*/}
                {/*        title: '平安校园管理预警系统',*/}
                {/*        eng: 'pingan',*/}
                {/*        desc: '自主研发的校园预警平台，通过自身的努力，保障学生的身心安全，协助学校共同打造智慧且安全的校园环境。'*/}
                {/*      })}*/}
                {/*      <img className={styles.bg} src={'/img/pingan_bg.png'}/>*/}
                {/*      <div className={styles.slider_content}>*/}
                {/*        <div className={styles.pingan_wrapper}>*/}
                {/*          <img className={styles.pingan_img_wrapper} src={'/img/pingan1.png'}/>*/}
                {/*          <div className={styles.info_wrapper}>*/}
                {/*            <div className={styles.info_item}>*/}
                {/*              <div className={styles.title}>1、预警信息</div>*/}
                {/*              <div className={styles.desc}>预警信息包括疑似校园贷、思想政治、精神类、疾病类等。以思想政治预警 为例，学生在登录后上网过程中，使用搜索引擎或者网页推送内容，涉及到*/}
                {/*                了如宗教、邪教等敏感关键词，系统将自动记录该搜索和浏览信息，并在后 台记录，记录内容包括：该学生的学号、姓名、院系班级、关联辅导员、电*/}
                {/*                话。信息经过加密脱敏后，只有特定权限的管理者才会看到完整的学生信息， 保护学生隐私。有效的避免宗教势力向校园环境的渗透。*/}
                {/*              </div>*/}
                {/*            </div>*/}

                {/*            <div className={styles.line}/>*/}

                {/*            <div className={styles.info_item}>*/}
                {/*              <div className={styles.title}>2、辅助指导类信息</div>*/}
                {/*              <div className={styles.desc}>系统对搜索引擎重点关注，将学生在日常上网中使用搜索引擎输入的 关键词和查询结果进行重点统计，并将搜索关键词进行了统计、分类*/}
                {/*                和排行，方便学校管理者对学生上网倾向进行分析了解，比如创业类 信息与知识资源等。*/}
                {/*              </div>*/}
                {/*            </div>*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </SwiperSlide>*/}

                {/*  <SwiperSlide>*/}
                {/*    <div className={styles.slider_container}>*/}
                {/*      {renderTitle({ title: '云通统一支付系统', eng: 'yuntong', desc: '统一收单服务', white: true })}*!/*/}
                {/*      <img className={styles.bg} src={'/img/yuntong_bg.png'}/>*/}
                {/*      <div className={styles.slider_content}>*/}
                {/*        <div className={styles.yuntong_wrapper}>*/}
                {/*          <img className={styles.img1} src={'/img/yuntong_left.png'}/>*/}
                {/*          <img className={styles.img2} src={'/img/chanpinyoushi.png'}/>*/}

                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </SwiperSlide>*/}

                {/*  <SwiperSlide>*/}
                {/*    <div className={styles.slider_container}>*/}
                {/*      {renderTitle({ title: '云芯卡管理系统', eng: 'yunxin', desc: '校园卡管理及服务', })}*/}
                {/*      <img className={styles.bg} src={'/img/yunxin_bg.png'}/>*/}
                {/*      <div className={styles.slider_content}>*/}
                {/*        <div className={styles.yunwang_wrapper}>*/}
                {/*          <img className={styles.wang_img} style={{ height: 'calc(100% - 200px)' }}*/}
                {/*               src={'/img/yunxin_top.png'}/>*/}
                {/*          <div className={styles.desc_wrapper} style={{ marginTop: '30px', textAlign: 'left' }}>*/}
                {/*            <div className={styles.img_title_wrapper}>*/}
                {/*              <img className={styles.icon} src={'/img/yunxin1.png'}/>*/}
                {/*              <div className={styles.title_wrapper}>*/}
                {/*                <div className={styles.title_b}>卡片管理</div>*/}
                {/*                <div className={styles.desc_b}>卡片对接：通过系统配置,能够快速与学校业务 进行对接；卡片管控：实现对卡片使用状态的管*/}
                {/*                  控,对卡片（用户）的关联业务进行管理。*/}
                {/*                </div>*/}
                {/*              </div>*/}
                {/*            </div>*/}
                {/*            <div className={styles.img_title_wrapper}>*/}
                {/*              <img className={styles.icon} src={'/img/yunxin2.png'}/>*/}
                {/*              <div className={styles.title_wrapper}>*/}
                {/*                <div className={styles.title_b}>用户管理</div>*/}
                {/*                <div className={styles.desc_b}>卡片对接：通过系统配置,能够快速与学校业务 进行对接；卡片管控：实现对卡片使用状态的管*/}
                {/*                  控,对卡片（用户）的关联业务进行管理。*/}
                {/*                </div>*/}
                {/*              </div>*/}
                {/*            </div>*/}
                {/*            <div className={styles.img_title_wrapper}>*/}
                {/*              <img className={styles.icon} src={'/img/yunxin3.png'}/>*/}
                {/*              <div className={styles.title_wrapper}>*/}
                {/*                <div className={styles.title_b}>身份认证</div>*/}
                {/*                <div className={styles.desc_b}>将同一用户在多个平台的不同身份进行统一,建 立统一的用户信息平台,实现统一身份管理。</div>*/}
                {/*              </div>*/}
                {/*            </div>*/}
                {/*            <div className={styles.img_title_wrapper}>*/}
                {/*              <img className={styles.icon} src={'/img/yunxin4.png'}/>*/}
                {/*              <div className={styles.title_wrapper}>*/}
                {/*                <div className={styles.title_b}>信用体系</div>*/}
                {/*                <div className={styles.desc_b}>将同一用户在多个平台的不同身份进行统一,建 立统一的用户信息平台,实现统一身份管理。</div>*/}
                {/*              </div>*/}
                {/*            </div>*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </SwiperSlide>*/}

                {/*  <SwiperSlide>*/}
                {/*    <div className={styles.slider_container}>*/}
                {/*      {renderTitle({ title: '云合物联网网关系统', eng: 'yunhe', desc: '物联网设备接入及标准化服务', white: true })}*/}
                {/*      <img className={styles.bg} src={'/img/yunhe_bg.png'}/>*/}
                {/*      <div className={styles.slider_content}>*/}
                {/*        <div className={styles.yunwang_wrapper}>*/}
                {/*          <img className={styles.wang_img} src={'/img/yunhe_top.png'}/>*/}
                {/*          <div className={styles.desc_wrapper}>*/}
                {/*            <div className={styles.img_title_wrapper}>*/}
                {/*              <img className={styles.icon} src={'/img/yunhe1.png'}/>*/}
                {/*              <div className={styles.title_wrapper}>*/}
                {/*                <div className={styles.title_w}>丰富的云端赋能</div>*/}
                {/*                <div className={styles.desc}>通过聚合能力、服务,赋能给第三方平台 及厂商形成共赢</div>*/}
                {/*              </div>*/}
                {/*            </div>*/}
                {/*            <div className={styles.img_title_wrapper}>*/}
                {/*              <img className={styles.icon} src={'/img/yunhe2.png'}/>*/}
                {/*              <div className={styles.title_wrapper}>*/}
                {/*                <div className={styles.title_w}>开放的平台生态</div>*/}
                {/*                <div className={styles.desc}>接入第三方应用开发者,为厂商及务商提 供更多应用层面支持</div>*/}
                {/*              </div>*/}
                {/*            </div>*/}
                {/*            <div className={styles.img_title_wrapper}>*/}
                {/*              <img className={styles.icon} src={'/img/yunhe3.png'}/>*/}
                {/*              <div className={styles.title_wrapper}>*/}
                {/*                <div className={styles.title_w}>全方位的云服务</div>*/}
                {/*                <div className={styles.desc}>提供从设备接入、数据存储到应用系统整 套云服务,省去您自己搭建平台的成本。</div>*/}
                {/*              </div>*/}
                {/*            </div>*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </SwiperSlide>*/}
                {/*</Swiper>*/}
            </div>
            <Footer/>
        </div>;
    }

}

export default PlatformDetail;
