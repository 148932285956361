import { Modal } from "antd";
import { PlayCircleOutlined } from '@ant-design/icons'
import { Player,BigPlayButton } from 'video-react';
import styles from './index.module.less';
import React, { useState, useEffect } from "react";
export default function VideoPlayer(props) {
    const playerRef = React.createRef();
    return (
        <>
            <Modal
                visible={props.visible}
                footer={false}
                width={1000}
                closable={false}
                onCancel={props.cancel}
                wrapClassName={styles.web}//对话框外部的类名，主要是用来修改这个modal的样式的
            >
                <Player
                    autoPlay
                    ref={playerRef}
                    src={props.urlVideo}
                    >
                        <BigPlayButton position="center"></BigPlayButton>
                </Player>
            </Modal>
        </>
    )
}