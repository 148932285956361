import React from 'react'
import styles from './index.module.less'

const Module = (props) => {
    return <div className={styles.module_wrapper} {...props}>
        {props.bg ? <img className={styles.bg} src={props.bg}/> : null}
        {props.hidetitle ? null : <div className={styles.module_header} style={props.titlestyle}>
            {props.title}
            <div className={styles.eng} style={props.titleengstyle}>
                {props.eng}
            </div>
            {<div className={styles.dot}>
                {
                    [1, 1, 1].map((ele, ind) => (<div key={ind} className={styles.dot_item}/>))
                }
            </div>}
        </div>}
        {props.children}
    </div>
}

export default Module
