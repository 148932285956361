import React from 'react';
import Style from './index.module.less';
import {Breadcrumb} from 'antd';

const Bread = (props) => {

    return <div className={Style.bread_wrapper}>
        <Breadcrumb separator=">">
            {
                props.breads.map(ele => {
                    return <Breadcrumb.Item key={ele.name} href={ele.href}>{ele.name}</Breadcrumb.Item>;
                })
            }
        </Breadcrumb>
    </div>;
};

export default Bread;
