import React, {useEffect} from 'react'
import styles from './index.module.less'
import ScrollReveal from "scrollreveal";
import {useHistory} from 'react-router-dom';

const PageTop = (props) => {
    const {bg, title, description, isSolution, titleTop, titleBottom} = props
    const history = useHistory();

    useEffect(() => {
        let title = {
            distance: '100px',
            origin: 'bottom',
            reset: true,
        };
        ScrollReveal().reveal(`.title`, title);
    }, [])

    return <div className={styles.top_wrapper}>
        {isSolution ? <div className={styles.solution_suspension}>
            <img src={'/img/solution_suspension.png'}/>
        </div> : null}
        <img src={bg}/>
        <div className={styles.title}>
            {title}
        </div>
        <div className={styles.description}>
            {description}
        </div>
        {isSolution ? <div className={styles.button_wrapper}>
            <span className={styles.button} onClick={() => {
                history.replace({
                    pathname: `/about`,
                });
            }}>立即咨询</span>
        </div> : null}
        <div className={styles.info_wrappers}>
            <div className={`${styles.info_wrapper_top} title`}>
                {titleTop}
            </div>
            <div className={`${styles.info_wrapper_bottom} title`}>
                {titleBottom}
            </div>
        </div>
    </div>
}
export default PageTop
