import React, {useEffect, useState, createRef} from 'react'
import styles from './index.module.less'
import Module from '../../../comp-mobile/module';
import Footer from '../../../comp-mobile/footer';
import PageTop_M from '../../../comp-mobile/pageTop';
import Header from '../../../comp-mobile/header';
import {Swiper, SwiperSlide} from "swiper/react";

const Cooperate_M = (props) => {

    const [current, setCurrent] = useState(0);
    const [swiperObj, setSwiperObj] = useState(null);
    let swiperRef = createRef();
    const slideChange = (e) => {
        setCurrent(e.activeIndex);
    };

    document.title = '商业合作'
    const cooperateList = [{
        icon: '/img/gaoxiao.png',
        title: '高校',
        desc: '基础建设、信息化建设、运营服务 、教学服务、教育实训、就业指导',
        bg: '/img/gaoxiao1.png'
    }, {
        icon: '/img/xiangmuyunyingshang.png',
        title: '项目运营商',
        desc: '项目共营、项目共建、平台服务、 技术支持',
        bg: '/img/yunying.png'
    }, {
        icon: '/img/tongxinyunyingshang.png',
        title: '通信运营商',
        desc: '项目共建、融合套餐',
        bg: '/img/tongxun.png'
    }, {
        icon: '/img/pingtaigongsi.png',
        title: '平台公司',
        desc: '数据共享、流量运营',
        bg: '/img/pingtai.png'
    }, {
        icon: '/img/shebeichangshang.png',
        title: '设备厂商',
        desc: '供应链合作、渠道资源共享',
        bg: '/img/changshang.png'
    }, {
        icon: '/img/pay_channel.png',
        title: '支付通道',
        desc: '用户流量',
        bg: '/img/tongdao.png'
    }, {
        icon: '/img/guanggaoshang.png',
        title: '广告商',
        desc: '广告合作',
        bg: '/img/guanggao.png'
    }, {
        icon: '/img/bank.png',
        title: '银行',
        desc: '金融服务、融合套餐、用户流量',
        bg: '/img/yinhang.png'
    },]

    const partners = [{
        img: '/img/wechat.png'
    }, {
        img: '/img/alipay.png'
    }, {
        img: '/img/tonglian.png'
    }, {
        img: '/img/yidong.png'
    }, {
        img: '/img/yunshanfu.png'
    }, {
        img: '/img/liantong.png'
    }]

    const schoolList = [
        {
            icon: '/img/anli/schoolBadge/zhongkeda.png',
            title: '中国科学技术大学',
            desc: '同“新”协力，中科大迎新再升级',
            bg: '/img/anli/zhongkeda.jpg'
        },
        {
            icon: '/img/anli/schoolBadge/shanyida.png',
            title: '山东第一医科大学',
            desc: '神州顶联助力山东第一医科大学高校迎新',
            bg: '/img/anli/shanyida.jpg'
        },
        {
            icon: '/img/anli/schoolBadge/shandongjianda.png',
            title: '山东建筑大学',
            desc: '与时偕行，神州顶联助山东建筑大学探索实践智慧校园建设',
            bg: '/img/anli/shandongjianda.jpg'
        },
        {
            icon: '/img/anli/schoolBadge/qilushifan.png',
            title: '齐鲁师范学院',
            desc: '智慧迎新，科技战疫——齐鲁师范学院应用“AI无接触”技术助力新学年',
            bg: '/img/anli/qilushifan.jpg'
        },
        {
            icon: '/img/anli/schoolBadge/jinanzhiye.png',
            title: '济南职业学院',
            desc: '智慧联动，神州顶联携手济南职业学院建设现代化校园',
            bg: '/img/anli/jinanzhiye.jpg'
        },
        {
            icon: '/img/anli/schoolBadge/xijiaocheng.png',
            title: '西安交通大学城市学院',
            desc: '神州顶联助力西安交通大学城市学院宿舍升级，共促校园信息化发展',
            bg: '/img/anli/xijiaocheng.jpg'
        },
        {
            icon: '/img/anli/schoolBadge/shandongshangzhi.png',
            title: '山东商业职业学院',
            desc: '“汗水管理”变“智慧管理”——山东商业职业学院升级宿舍服务',
            bg: '/img/anli/shandongshangzhi.jpg'
        },
        {
            icon: '/img/anli/schoolBadge/shandongchuanmei.png',
            title: '山东传媒职业学院',
            desc: '聚焦“底子”功夫，构建校园安全防护墙',
            bg: '/img/anli/shandongchuanmei.jpg'
        },
    ]

    const whyList = [
        {
            title: '投建运营能力',
            icon: 'icon-di1tiao-dingliannengli',
            desc: '为高校免费投建硬件设备及配套管理软件。打酒一体化服务运营，提高师生体验，与中国科学技术大学、西安交通大学、山东师范大学等国内300余所校园建立合作关系，为300多万学生提供全面运营及专业售后服务。',
        }, {
            title: '商业创新能力',
            icon: 'icon-di2tiao--dignliannengli',
            desc: '深耕探索校园数字化建设，创新服务模式为学校提供更贴合实际需求的产品与解决方案；探索多元化业务模式，创新合作模式为客户提供方案规划、投资建设、运营服务、项目定制等业务,与合作伙伴共建共享共生。',
        }, {
            title: '顶层设计能力',
            icon: 'icon-di3tiao-dinglianhezuo',
            desc: '深耕校园数字化十余年，具备“从顶到底，从软到硬”的能力，顶层设计规划经验丰富，底层建设基础逻辑完备，在数智校园建设规划中将持续深化“五个一”数据治理模式,以数据驱动校园业务重构。',
        }, {
            title: '资源整合能力',
            icon: 'icon-di4tiao-dibngliannengli',
            desc: '布局全国市场，与国内百余家项目运营商、上下游企业、通信运营商、银行等保持长期战略合作，已在全国落地300余个校园服务项目；同时，实现了与华为、阿里、腾讯等合作伙伴的产品联合研发、市场联动拓展。',
        }
    ]

    useEffect(() => {

        const {type} = props.match.params
        if (type) {
            let anchorElement = document.getElementById(type)
            if (anchorElement) {
                anchorElement.scrollIntoView()
            }
        }

    }, [])

    useEffect(() => {
        const {type} = props.match.params
        if (type) {
            let anchorElement = document.getElementById(type)
            if (anchorElement) {
                anchorElement.scrollIntoView()
            }
        }

    }, [props.match.params])
    return <div className={styles.container_wrapper}>
        <Header/>
        <PageTop_M bg={'/img/hezuo_bg_M.png'}/>
        <div className={styles.content_wrapper}>
            <div className={styles.why_wrapper} id={'why'}>
            <h2 style={{textAlign: 'center'}}>为什么要成为顶联合作者</h2>
                {
                    whyList.map(ele => {
                        return <div className={styles.why_item}>
                            <svg className={styles.icon} aria-hidden="true">
                                <use xlinkHref={`#${ele.icon}`}>
                                </use>
                            </svg>
                            {/*<img src={ele.icon}/>*/}
                            <div className={styles.icon_text}>
                                <h4>{ele.title}</h4>
                                <div style={{fontSize: 13, color: '#666'}}>{ele.desc}</div>
                            </div>
                        </div>
                    })
                }
            </div>
            <Module id={'moshi'} title={'合作模式'} eng={'Cooperation Mode'}>
                <div className={styles.cooperate_wrapper}>
                    {
                        cooperateList.map((ele, index) => {
                            return <div className={styles.cooperate_item} key={index}>

                                <div className={styles.cooperate_item_back}>
                                    <img src={ele.bg} className={styles.bg}/>
                                    <img className={styles.cooperate_img} src={ele.icon}/>
                                    <div className={styles.cooperate_title}>{ele.title}</div>
                                    <div className={styles.cooperate_desc}>{ele.desc}</div>
                                </div>
                                <div className={styles.cooperate_item_front}>

                                    <img className={styles.cooperate_img} src={ele.icon}/>
                                    <div className={styles.cooperate_title}>{ele.title}</div>
                                    <div className={styles.cooperate_desc}>{ele.desc}</div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </Module>

            <Module id={'partner'} title={'合作伙伴'} eng={'Partner'} style={{backgroundColor: '#f4f8fc'}}>
                <div className={styles.partner_wrapper}>
                    {partners.map((ele, index) => {
                        return <div className={styles.partner_item} key={index}>
                            <img src={ele.img}/>
                        </div>
                    })}
                </div>
            </Module>

            <Module id={'anli'} title={'合作案例'} eng={'Casus'}>
                <div className={styles.cooperate_wrapper}>
                    {
                        schoolList.map((ele, index) => {
                            return <div className={styles.cooperate_item} key={index}>

                                <div className={styles.cooperate_item_back}>
                                    <img src={ele.bg} className={styles.bg}/>
                                    <div className={styles.cooperate_desc}>{ele.desc}</div>
                                </div>
                                <div className={styles.cooperate_item_front}>

                                    <img className={styles.cooperate_img} src={ele.icon}/>
                                    <div className={styles.cooperate_title}>{ele.title}</div>
                                    <div className={styles.cooperate_desc}>{ele.desc}</div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </Module>

            <Module id={'anli'} title={'合作院校'} eng={'Partner'}>
                <div className={styles.swiper_wrapper}>
                    <Swiper
                        ref={swiperRef}
                        grabCursor
                        autoplay
                        navigation
                        loop={true}
                        onSlideChange={slideChange}
                        onSwiper={swiper => (setSwiperObj(swiper))}>
                        {
                            [
                                '/img/school_1.jpg', '/img/school_2.jpg', '/img/school_3.jpg', '/img/school_4.jpg',
                            ].map((ele, index) => {
                                console.log(ele)
                                return <SwiperSlide key={index}>
                                    <div className={styles.content_wrapper}>
                                        <img src={ele}/>
                                    </div>
                                </SwiperSlide>;
                            })
                        }
                    </Swiper>
                </div>
            </Module>
            <Footer/>
        </div>
    </div>
}
export default Cooperate_M
