// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".G2S2XAe8SFw7aqfQSYU33g\\=\\= {\n  width: 1000px;\n  margin: 0 auto;\n  padding: 20px 0;\n  border-bottom: 1px solid #ccc;\n}\n", "",{"version":3,"sources":["webpack://src/components/bread/index.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;EACA,eAAA;EACA,6BAAA;AACF","sourcesContent":[".bread_wrapper{\n  width: 1000px;\n  margin: 0 auto;\n  padding: 20px 0;\n  border-bottom: 1px solid #ccc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bread_wrapper": "G2S2XAe8SFw7aqfQSYU33g=="
};
export default ___CSS_LOADER_EXPORT___;
