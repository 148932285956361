import React from "react";
import { PRIVACY } from "../../contant";
import style from './index.module.less'

const Index = () => {

    return <div className={style.wrapper}>
        <div className={style.title}>隐私政策</div>
        {/* <div className={style.content}>
            {PRIVACY}
        </div> */}

        <div className={style.content} dangerouslySetInnerHTML={{ __html: PRIVACY }}>
            {/* {PRIVACY} */}
        </div>

    </div>
}
export default Index