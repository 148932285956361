import React from 'react';
import {Button} from 'antd'
import {RightOutlined} from '@ant-design/icons'
import Header from '../../../components/header';
import Bottom from '../../../components/contact';
import Reserve from '../../../components/reserve';
import BasicLayout from '../../../layout/basicLayout';
import Module from '../../../components/module';
import Style from './index.module.less';
import {NEWS} from '../../../contant';
import Bread from '../../../components/bread';
import PageTop from '../../../components/pageTop';

const News = (props) => {

    return <BasicLayout>
        <Header fixed/>
        <div className={Style.content_wrapper}>
            <PageTop bg={'/img/banner_news.png'} title={'数据驱动，重构校园管理新局面'}/>
            <Bread breads={[{name: '首页', href: '#/home'}, {name: '关于我们', href: '#/about'}, {
                name: '新闻中心',
                href: '#/news'
            }]}/>
            <Module title={'新闻中心'} eng={'NEWS CENTER'}>
                <div className={Style.news_wrapper}>
                    {NEWS.map(ele => {
                        return <div key={ele.title} className={Style.news_item} onClick={() => {
                            props.history.push({
                                pathname: '/news/detail',
                                query: {title: ele.title}
                            })
                        }}>
                            <img src={ele.img}/>
                            <div className={Style.title}>{ele.title}</div>
                            <div className={Style.date}>{ele.date}</div>
                            <div style={{marginTop: '16px', marginBottom: '20px'}}>
                                <Button style={{color: '#999'}}>详情 <RightOutlined/></Button>
                            </div>
                        </div>
                    })
                    }
                </div>
            </Module>

        </div>
        <Bottom/>
        <Reserve/>
    </BasicLayout>;
};
export default News;
