// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KaFeFXRuwz\\+8eo70dpQupw\\=\\= {\n  background-color: #051431;\n  text-align: center;\n  color: white;\n  line-height: 60px;\n}\n", "",{"version":3,"sources":["webpack://src/components/reserve/index.module.less"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,iBAAA;AACF","sourcesContent":[".reserve_wrapper {\n  background-color: #051431;\n  text-align: center;\n  color: white;\n  line-height: 60px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reserve_wrapper": "KaFeFXRuwz+8eo70dpQupw=="
};
export default ___CSS_LOADER_EXPORT___;
