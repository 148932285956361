// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8NQ5pgs2KEb9yUF-9WQKtg\\=\\= {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  height: 58px;\n  display: flex;\n  border-bottom: 1px solid #ccc;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 20px;\n  z-index: 9999;\n  transition: all 0.3s;\n  box-sizing: border-box;\n  background-color: white;\n}\n._8NQ5pgs2KEb9yUF-9WQKtg\\=\\= ._1ifZTVER5CanNqm5sT7c6g\\=\\= {\n  height: 40px;\n  width: 180px;\n}\n._8NQ5pgs2KEb9yUF-9WQKtg\\=\\= .C1QifXBJsc6eEMiyNj-EzQ\\=\\= {\n  width: 50px;\n  height: 50px;\n  text-align: right;\n  line-height: 50px;\n}\n.ant-popover {\n  width: 200px !important;\n}\n", "",{"version":3,"sources":["webpack://src/comp-mobile/header/index.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,YAAA;EACA,aAAA;EACA,6BAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,aAAA;EACA,oBAAA;EACA,sBAAA;EACA,uBAAA;AACF;AAfA;EAmBI,YAAA;EACA,YAAA;AADJ;AAnBA;EAwBI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,iBAAA;AAFJ;AAMA;EACE,uBAAA;AAJF","sourcesContent":[".header_wrapper {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  height: 58px;\n  display: flex;\n  border-bottom: 1px solid #ccc;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 20px;\n  z-index: 9999;\n  transition: all .3s;\n  box-sizing: border-box;\n  background-color: white;\n\n\n  .logo {\n    //width: 180px;\n    height: 40px;\n    width: 180px;\n  }\n\n  .menu_icon{\n    width: 50px;\n    height: 50px;\n    text-align: right;\n    line-height: 50px;\n  }\n}\n\n:global(.ant-popover) {\n  width: 200px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header_wrapper": "_8NQ5pgs2KEb9yUF-9WQKtg==",
	"logo": "_1ifZTVER5CanNqm5sT7c6g==",
	"menu_icon": "C1QifXBJsc6eEMiyNj-EzQ=="
};
export default ___CSS_LOADER_EXPORT___;
