import React, {useEffect, useState} from 'react';
import styles from './index.module.less';
import SwiperC from './components/swiper';
import ScrollReveal from 'scrollreveal';
import BasicLayout from '../../../layout/basicLayout';
import Header from '../../../components/header';
import Bottom from '../../../components/contact';
import Reserve from '../../../components/reserve';
import Module from '../../../components/module';
import Style from '../../../layout/basicLayout.moudle.less';
import {
    sceneData,
    WATER_SAVING_PRODUCTS_Temporary,
    CHANNEL_PRODUCTS_TEMPORARY,
    LOCK_PRODUCTS_TEMPORARY,
    FUSION_GATEWAY, HOME_MAIN_BUSINESS
} from '../../../contant';
import {useHistory} from 'react-router-dom';
import Solution_Module from "../../../components/module/solution";

const Home = () => {

    const history = useHistory();
    const scene1 = sceneData.slice(0, 7);
    const scene2 = sceneData.slice(7, 14);
    const products = [
        {...FUSION_GATEWAY, type: 'gateway'},
        {...WATER_SAVING_PRODUCTS_Temporary[0], type: 'water_saving'},
        {...WATER_SAVING_PRODUCTS_Temporary[2], type: 'water_saving'},
        {...LOCK_PRODUCTS_TEMPORARY[0], type: 'door_lock'},
        {...CHANNEL_PRODUCTS_TEMPORARY[3], type: 'channel'},
    ]
    const partnerList = [
        {
            count: 300,
            unit: '所',
            title: '高校',
        }, {
            count: 200,
            unit: '余万',
            title: '用户',
        }, {
            count: 100,
            unit: '项',
            title: '专利软著',
        }, {
            count: 50,
            unit: '家',
            title: '平台合作伙伴',
        }, {
            count: 10,
            unit: '家',
            title: '支付合作平台',
        }, {
            count: 200,
            unit: '家',
            title: '合作伙伴',
        }];
    const [curProItem, setCurProItem] = useState(0);

    useEffect(() => {
        scene1.forEach((ele, index) => {
            let scene_item = {
                distance: '100px',
                origin: 'bottom',
                reset: true,
                delay: index * 50,
            };
            ScrollReveal().reveal(`.scene_item${index}`, scene_item);
        });
        scene2.forEach((ele, index) => {
            let scene_item = {
                distance: '100px',
                origin: 'bottom',
                reset: true,
                delay: index * 50,
            };
            ScrollReveal().reveal(`.scene_item_r2${index}`, scene_item);
        });
    }, []);

    return <BasicLayout>
        <Header/>
        <div className={Style.content_wrapper}>
            <SwiperC/>
            <Solution_Module title={'主营业务'} bg={'/img/solution/common/module_bg/01.png'}
                             style={{padding: '50px 80px'}}>
                <div className={styles.authScene_wrapper}>
                    <div className={styles.scene_row_wrapper}>
                        {HOME_MAIN_BUSINESS.map(
                            (item, index) => {
                                return <div key={`scene_item${index}`}
                                            onClick={() => {
                                                history.replace({
                                                    pathname: `${item.to}`,
                                                    state: {type: item.type},
                                                });
                                            }}
                                            className={`${styles.scene_item} scene_item${index}`}>
                                    <img className={'img_bg'}
                                         src={item.img}/>
                                    <div className={styles.info_wrapper}>
                                        <div className={styles.icon_wrapper}>
                                            <div>{item.title}</div>
                                        </div>
                                        <div className={styles.intro}>{item.description}</div>
                                    </div>

                                </div>;
                            })
                        }

                    </div>
                </div>
            </Solution_Module>

            <Solution_Module title={'热销产品'} bg={'/img/solution/common/module_bg/05.png'} style={{padding:'50px 20%'}}>
                <div className={styles.product_wrapper}>
                    <div className={styles.platform_item_wrapper}>
                        {products.map((ele, index) => (
                            <div key={`product${index}`} onClick={() => {
                                setCurProItem(index);
                            }} className={styles.platform_item}>
                                {ele.titless}
                            </div>
                        ))}
                    </div>
                    <div className={styles.platform_intro_wrapper}>
                        <div>
                            {products[curProItem].type !== 'gateway' ? <div onClick={() => {
                                history.push({
                                    pathname: '/productDetail',
                                    query: {title: products[curProItem].title, type: products[curProItem].type}
                                })
                            }}>
                                <div className={styles.title}>{products[curProItem].title}
                                    <div className={styles.jiantou}>
                                        <img src={'/img/jiantou.png'}/>
                                    </div>
                                </div>
                            </div> : <div className={styles.gateway}>{products[curProItem].title}</div>}
                            <div className={styles.explain}>{products[curProItem].detail.scene}</div>
                            <div className={styles.wrapper}>
                                <div className={styles.explain_wrapper}>
                                    {products[curProItem].detail.teses.map((ele, index) => (
                                        <div key={`explain${index}`}>
                                            <div className={styles.explain_item}>
                                                <img src={ele.icon} className={styles.icon_img}/>
                                                <span className={styles.desc}>{ele.content}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <img src={products[curProItem].imgs} className={styles.info_img}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Solution_Module>

            <Solution_Module title={'平台及服务'} bg={'/img/solution/common/module_bg/01.png'} style={{padding:'50px 20%'}}>
                <div className={styles.services}>
                    <img src={'/img/pingtaijifuwu_home.png'}/>
                </div>
            </Solution_Module>

            {/*<Module title={'公司简介'} eng={'About Toplion'}*/}
            {/*        style={{backgroundColor: '#F8F8F8'}}/>*/}
            {/*<div className={styles.company_content}>*/}
            {/*    <img className={styles.comp_img} src={'/img/toplion02.png'}/>*/}
            {/*    <div className={styles.description}>*/}
            {/*        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;神州顶联科技有限公司（简称：神州顶联）成立于2007年，神州数码集团股份有限公司教育业务核心企业。秉持“以数据驱动业务重构，极大提升用户应用体验”的服务理念，积极推动中国校园智能化跨越式发展，致力于成为校园客户最信赖的合作伙伴。<br/>*/}
            {/*        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;作为行业领先的智慧校园解决方案与投建运营服务商，创新B2B2C（学校-渠道商-用户）商业模式，围绕“教、学、研、管、服、评”6个教育数字化场景，结合自研智能感知终端，打造了“智慧网络、融合网关、智慧门禁、智慧生活、智慧零售、一校通、智慧学工、智慧后勤、AI校园、数据治理”十大解决方案，深化一终端多能，一数据多用，一网络通行，一平台通办、一校园智安的“五个一”校园数据治理理念，为处在不同阶段的各类院校提供基础设施升级和数字驱动服务，全面推动校园数字化、智能化转型服务。<br/>*/}
            {/*        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;未来，神州顶联将继续深耕智慧校园市场，持续加大产品研发与创新；把用户服务放在第一位，完善服务体系，为用户提供高品质应用体验；坚持开放、共享、共赢的原则，构建优势互补、可持续发展的生态体系。为推动中国教育高质量发展贡献一份力量！*/}
            {/*    </div>*/}
            {/*</div>*/}

            <Module title={'生态伙伴'} eng={'Ecological Partnership'}
                    titlestyle={{color: 'white'}}
                    titleengstyle={{color: 'white'}}
                    bg={'/img/partner_bg.png'}>
                <div className={styles.content_wrapper}
                     style={{paddingLeft: '10%'}}>
                    {
                        partnerList.map((ele, index) => (
                            <div key={`partner${index}`}
                                 className={styles.content_item}>
                                <div className={styles.count_wrapper}>
                                    <span
                                        className={styles.count}>{ele.count}</span>
                                    <span
                                        className={styles.unit}>{ele.unit}</span>
                                    <span className={styles.plus}>+</span>
                                </div>
                                <div className={styles.line}/>
                                <div
                                    className={styles.item_type}>{ele.title}</div>
                            </div>
                        ))
                    }
                </div>
            </Module>
        </div>
        <Bottom/>
        <Reserve/>
    </BasicLayout>
};
export default Home;
