const splitArray = (arr, size) => {
  let newArr = [];
  for (let i = 0; i < arr.length; i += size) {
    newArr.push(arr.slice(i, i + size));
  }
  return newArr;
}

export const seat = (arr, size) => {
  let newArray = splitArray(arr, size);
  if (newArray[newArray.length - 1]?.length < size) {
    const needSeat = size - newArray[newArray.length - 1]?.length;
    let needArray = [];
    for (let i = 0; i < needSeat; i++) {
      needArray.push({})
    }
    newArray[newArray.length - 1].push(...needArray);
  }
  return newArray;
}