import React, {useEffect, useState, createRef} from 'react';
import styles from './index.module.less';
import ScrollReveal from 'scrollreveal';
import {useHistory} from 'react-router-dom';
import Module from '../../../comp-mobile/module';
import Footer from '../../../comp-mobile/footer';
import PageTop_M from '../../../comp-mobile/pageTop';
import Header from '../../../comp-mobile/header';
import {drawerData_Temporary, platforms, SOLUTIONS, SOLUTIONS_HEADER_LIST} from '../../../contant';
import Solution_Module from "../../../comp-mobile/module/solution";

const Platform_M = (props) => {
    document.title = '解决方案'
    const history = useHistory();
    const [solution, setSolution] = useState({
        type: '',
        banner_M: '',
        title: '',
        title_long: '',
        description: '',
        fanganzhengtijiagou: '',
        shujukeshihuadaping: [],
        fanganjiazhiliangdian: [],
        yingjianchanpintushi: [],
    });
    const [type, setType] = useState('net')
    useEffect(() => {
        initData(type)
        return () => {

        }
    }, []);
    const initData = (e) => {
        let state = props.location.pathname.split('/')[2]
        let type = props.location.state ? props.location.state.type : state
        const item = SOLUTIONS.find(ele => ele.type === type);
        setSolution(item);
        setType(type)
    }

    useEffect(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0
        initData(type)
    }, [props.location.state])

    return <div className={styles.container_wrapper}>
        <Header/>
        <div className={styles.top_wrapper}>
            <img className={styles.banner} src={solution.banner_M}/>
            <div className={styles.wrapper}>
                <div className={styles.title}>{solution.title}</div>
                <div className={styles.description}>{solution.description}</div>
                <span className={styles.button} onClick={() => {
                    history.replace({
                        pathname: `/about`,
                    });
                }}>立即咨询</span>
            </div>
        </div>
        <div className={styles.content_wrapper}>
            <Solution_Module title={'解决方案'} style={{borderRadius: 12}}>
                <div className={styles.IOT_tab_wrapper}>
                    {SOLUTIONS_HEADER_LIST.map((ele, index) => {
                        return <div key={ele.title}>
                            <div className={styles.IOT_tab_item}
                                 style={{color: index === 0 ? '#327DF9' : index === 1 ? '#EA8914' : index === 2 ? '#54C3F1' : index === 3 ? '#7CC636' : '#9F64FC'}}
                            >
                                <div style={{
                                    position: 'absolute',
                                    content: '',
                                    width: '80%',
                                    height: 2,
                                    bottom: 3,
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    zIndex: -1,
                                    backgroundColor: index === 0 ? '#327DF9' : index === 1 ? '#EA8914' : index === 2 ? '#54C3F1' : index === 3 ? '#7CC636' : '#9F64FC'
                                }}
                                ></div>
                                {ele.title}</div>
                            <div>{ele.children.map(e => {
                                return <div className={styles.item_title} key={e.title}
                                            style={{backgroundColor: index === 0 ? '#D6E5FE' : index === 1 ? '#FFEDD7' : index === 2 ? '#DDF3FC' : index === 3 ? '#ECF7E1' : '#E8E4F4'}}
                                            onClick={() => {
                                                history.replace({
                                                    pathname: `${e.to}`,
                                                    state: {type: e.type},
                                                });
                                            }}
                                >{e.title === '新一代一卡通' ? '一卡通' : e.title}
                                </div>
                            })}</div>
                        </div>
                    })}
                </div>
            </Solution_Module>
            <Solution_Module title={'方案整体架构'} bg={'/img/solution/common/module_bg/01.png'}>
                <img className={styles.fanganzhengtijiagou_img} src={solution.fanganzhengtijiagou}/>
            </Solution_Module>

            {solution.type === 'sell' ?
                <Solution_Module title={'硬件产品图示'} bg={'/img/solution/common/module_bg/02.png'}>
                    <div className={styles.product_img_wrapper}>
                        <img style={{width: 200, marginBottom: 20}} src={solution.yingjianchanpintushi[0]}/>
                        <img style={{width: 300}} src={solution.yingjianchanpintushi[1]}/>
                    </div>
                </Solution_Module> : solution.type === 'portal' ?
                    <>
                        <Solution_Module title={'校园门户WEB端'} bg={'/img/solution/common/module_bg/02.png'}>
                            <img className={styles.fanganzhengtijiagou_img} src={solution.xiaoyuanmenhu_web}/>
                        </Solution_Module>
                        <Solution_Module title={'校园门户APP端'} bg={'/img/solution/common/module_bg/04.png'}>
                            <img className={styles.fanganzhengtijiagou_img} src={solution.xiaoyuanmenhu_app}/>
                        </Solution_Module>
                    </>
                    :
                    <Solution_Module title={'数据可视化大屏'} bg={'/img/solution/common/module_bg/02.png'}>
                        <img className={styles.fanganzhengtijiagou_img} src={solution.shujukeshihuadaping[0]?.img}/>
                    </Solution_Module>}

            <Solution_Module title={'方案价值亮点'} bg={'/img/solution/common/module_bg/03.png'}
                             style={{padding: '30px 5%'}}>
                <div className={styles.project_value_wrapper}>
                    {solution.fanganjiazhiliangdian.map((e, index) => {
                        return <span className={styles.item_wrapper}>
                                <div className={styles.item}>
                                    <div className={styles.img_wrapper}>
                                        <img className={styles.img}
                                             src={`/img/solution/common/fanganjiazhiliangdian_icon/icon_0${index + 1}.png`}/>
                                    <div className={styles.line}/>
                                    </div>
                                    <div>
                                        <div className={styles.title}>{e.title}</div>
                                        <div className={styles.description}>{e.description}</div>
                                    </div>
                                </div>
                            </span>
                    })}
                </div>
            </Solution_Module>
            <Footer/>
        </div>
    </div>;
};
export default Platform_M;
