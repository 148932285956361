import React, {useEffect, useState} from 'react';
import Header from '../../../comp-mobile/header';
import BasicLayout from '../../../layout/basicLayout';
import Style from './index.module.less';
import {
    APP,
    CHANNEL_PRODUCTS_TEMPORARY, CLASSROOM_PRODUCTS_TEMPORARY,
    CONSUMPTION_LIFE_PRODUCTS_Temporary, LOCK_PRODUCTS_TEMPORARY,
    PARK_PRODUCTS_TEMPORARY,
    SCHOOLNET_LIFE_PRODUCTS_Temporary,
    SHARE_PRODUCTS_Temporary,
    WATER_SAVING_PRODUCTS_Temporary
} from '../../../contant';
import Bread from '../../../components/bread_M';
import PageTop_M from '../../../comp-mobile/pageTop';

const ProductDetail_M = (props) => {
    const [product, setProduct] = useState({detail: {}, tables: []})
    const {title, type} = props.location.query
    useEffect(() => {
        switch (type) {
            case 'water_saving':
                setProduct(WATER_SAVING_PRODUCTS_Temporary.find(ele => ele.title === title))
                break;
            case 'shared':
                setProduct(SHARE_PRODUCTS_Temporary.find(ele => ele.title === title))
                break;
            case 'consumer':
                setProduct(CONSUMPTION_LIFE_PRODUCTS_Temporary.find(ele => ele.title === title))
                break;
            case 'network':
                setProduct(SCHOOLNET_LIFE_PRODUCTS_Temporary.find(ele => ele.title === title))
                break;
            case 'channel':
                setProduct(CHANNEL_PRODUCTS_TEMPORARY.find(ele => ele.title === title))
                break;
            case 'parking':
                setProduct(PARK_PRODUCTS_TEMPORARY.find(ele => ele.title === title))
                break;
            case 'door_lock':
                setProduct(LOCK_PRODUCTS_TEMPORARY.find(ele => ele.title === title))
                break;
            case 'classroom':
                setProduct(CLASSROOM_PRODUCTS_TEMPORARY.find(ele => ele.title === title))
                break;
                case 'app':
                setProduct(APP.find(ele => ele.title === title))
                break;

        }

    })
    return <BasicLayout>
        <Header fixed/>
        <PageTop_M bg={'/img/banner_pro_detail.png'} title={'聚焦体验，打造校园服务新格局'}/>
        <div style={{width: 200}}>
            <Bread breads={[{name: '首页', href: '#/home'}, {name: '产品', href: '#/product'}, {name: '产品详情'},]}/>
        </div>
        <div className={Style.product_detail_wrapper}>
            <div style={{textAlign: "center"}}>
                <div className={Style.title}>{product.title}</div>
            </div>

            <div className={Style.info_wrapper}>
                <div className={Style.info_intro}>
                    <div style={{textAlign: "center"}}>
                        <img src={product.img} className={Style.info_img}/>
                        <div>{product.version}</div>
                    </div>
                    {product.detail.scene !== '' ? <><div className={Style.title}>适用场景</div>
                        <div className={Style.desc}>{product.detail.scene}</div></>
                        : null
                }

                    <div className={Style.title}>产品简介</div>
                    <div className={Style.desc}>{product.detail.intro}</div>

                    <div className={Style.title}>产品特色</div>
                    <div className={Style.desc}>{product.detail.tese}</div>
                </div>
            </div>
        </div>
    </BasicLayout>;
};
export default ProductDetail_M;
