import {useHistory, useLocation} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import styles from './index.module.less'
import {platforms, sceneData} from '../../contant'

const Bottom = () => {
    const history = useHistory()
    return <div className={styles.bottom_wrapper}>
        <div className={styles.options_wrapper}>
            <div className={styles.options_item}>
                <div className={styles.option_title}>关于我们</div>
                <div className={styles.sp_line}>
                    <div className={styles.blue}/>
                </div>
                <div className={styles.list_wrapper}>
                    <span style={{marginRight: 20}}>
                    <div className={styles.option_text} onClick={() => {
                        history.push('/about/intro')
                    }}>公司简介
                </div>
                <div className={styles.option_text} onClick={() => {
                    history.push('/about/history')
                }}>发展历程
                </div>
                <div className={styles.option_text} onClick={() => {
                    history.push('/about/honor')
                }}>荣誉资质
                </div>
                {/*        <div className={styles.option_text} onClick={() => {*/}
                {/*            history.push('/video')*/}
                {/*        }}>视频库*/}
                {/*</div>*/}
                </span>
                    <span>
                    <div className={styles.option_text} onClick={() => {
                        history.push('/news')
                    }}>新闻中心
                    </div>
                    <div className={styles.option_text} onClick={() => {
                        history.push('/about/contact')
                    }}>联系我们
                    </div>
                    <div className={styles.option_text} onClick={() => {
                        history.push('/join')
                    }}>加入我们
                    </div>
                </span>
                </div>
            </div>
            {/*<div className={styles.options_item}>*/}
            {/*    <div className={styles.option_title}>服务平台</div>*/}
            {/*    <div className={styles.sp_line}>*/}
            {/*        <div className={styles.blue}/>*/}
            {/*    </div>*/}
            {/*    <div style={{display: 'flex'}}>*/}
            {/*        <div>*/}
            {/*            {*/}
            {/*                platforms().slice(0, 6).map((ele, index) => <div key={`bottomplatform${index}`}*/}
            {/*                                                                 className={styles.option_text}*/}
            {/*                                                                 onClick={() => {*/}
            {/*                                                                     history.push(ele.to)*/}
            {/*                                                                 }}>{ele.title}</div>)*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*        <div style={{marginLeft: '50px'}}>*/}
            {/*            {*/}
            {/*                platforms().slice(6, 11).map((ele, index) => <div key={`bottomplatform${index}`}*/}
            {/*                                                                  className={styles.option_text}*/}
            {/*                                                                  onClick={() => {*/}
            {/*                                                                      history.push(ele.to)*/}
            {/*                                                                  }}>{ele.title}</div>)*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    /!*<div className={styles.option_text} onClick={() => {history.push('/platformDetail/0')}}>查看更多 >></div>*!/*/}
            {/*</div>*/}
            {/*<div className={styles.options_item}>*/}
            {/*  <div className={styles.option_title}>技术支持</div>*/}
            {/*  <div className={styles.sp_line}>*/}
            {/*    <div className={styles.blue}/>*/}
            {/*  </div>*/}
            {/*  <div className={styles.option_text} onClick={() => {history.push('/documents')}}>文档中心</div>*/}
            {/*  <div className={styles.option_text} onClick={() => {history.push('/')}}>常见问题</div>*/}
            {/*<div style={{display:'flex'}}>*/}

            {/*  <div>*/}
            {/*    {*/}
            {/*      sceneData.slice(0,7).map((ele,index)=><div key={`bottom${index}`} className={styles.option_text} onClick={() => {history.push(ele.to)}}>{ele.title}</div>)*/}
            {/*    }*/}
            {/*  </div>*/}
            {/*  <div style={{marginLeft:'50px'}}>*/}
            {/*    {*/}
            {/*      sceneData.slice(7,14).map((ele,index)=><div key={`bottom${index}`} className={styles.option_text} onClick={() => {history.push(ele.to)}}>{ele.title}</div>)*/}
            {/*    }*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*</div>*/}

        </div>
        <div className={styles.line}/>
        <div className={styles.qrcode_wrapper}>
            <div className={styles.qrcode_text}>
                <div className={styles.mobile}>商务合作：0531-5862 6000
                </div>
                <div className={styles.zixun}>投诉邮箱：toplion@toplion.com.cn<br/>(您对我们的产品、服务有任何不满均可投诉)
                </div>
                <div className={styles.zixun}>联系地址：山东省济南市历下区经十路8000号<br/>龙奥金座1号楼16层
                </div>
            </div>
            <div className={styles.qrcode}>
                <img src={'/img/qrcode.jpg'}/>
                <div>扫码并关注<br/>实时查看更多信息</div>
            </div>
        </div>

    </div>
}

export default Bottom
