import React from 'react'
import dayjs from "dayjs";
import styles from './index.module.less'

const Reserve = () => {
    return <div className={styles.reserve_wrapper}>Copyright © 2007 - {dayjs().format('YYYY')} 神州顶联科技有限公司 ALL
        Rights Reserved.
        <a target={'blank'} href={'https://beian.miit.gov.cn/'}>鲁ICP备15004431号-1</a>
        <a style={{marginLeft: 16}} target={'blank'} href={'https://beian.mps.gov.cn/#/query/webSearch?code=37010102000850'}>
            <img src={'/img/ga.png'}
                 style={{width: 16, height: 16}}/>
            鲁公网安备37010102000850号
        </a>
    </div>
}

export default Reserve
