import React, {useEffect, useState} from 'react';
import Header from '../../../../components/header';
import Bottom from '../../../../components/contact';
import Reserve from '../../../../components/reserve';
import BasicLayout from '../../../../layout/basicLayout';
import {SCHOOL} from '../../../../contant';
import Bread from '../../../../components/bread';

const CooperateDetail = (props) => {
    const [school,setSchool] = useState({})
    useEffect(()=>{
        const {title} = props.location.query
        const school = SCHOOL.find(ele=>ele.title === title)
        setSchool(school)
    })
    return <BasicLayout>
        <Header fixed/>
        <div >
            <div style={{height:'100px'}}/>
            <Bread breads = {[{name:'首页',href: '#/home'},{name:'商业合作',href:'#/cooperate'},{name:'案例详情'}]}/>
            {school.content}
        </div>
        <Bottom/>
        <Reserve/>
    </BasicLayout>;
};
export default CooperateDetail;
