import './App.less';
import {Redirect, Switch} from 'react-router';
import React, {useEffect, useState} from 'react';
import {HashRouter, Route} from 'react-router-dom';
import Home from './pages/pc/home';
import Product from './pages/pc/product';
import ProductDetail from './pages/pc/product/detail';
import Platform from './pages/pc/platform';
import Cooperate from './pages/pc/cooperate';
import About from './pages/pc/about';
import PlatformDetail from './pages/pc/platform/detail';
import Join from './pages/pc/join';
import News from './pages/pc/news';
import NewsDetail from './pages/pc/news/details';
import Solutions from './pages/pc/solutions';
import CooperateDetail from './pages/pc/cooperate/caseDetails';
import Video from './pages/pc/video'

import Home_M from './pages/mobile/home';
import Product_M from './pages/mobile/product';
import Cooperate_M from './pages/mobile/cooperate';
import Join_M from './pages/mobile/join';
import About_M from './pages/mobile/about';
import Platform_M from './pages/mobile/platform';
import PlatformDetail_M from './pages/mobile/platform/detail';
import ProductDetail_M from './pages/mobile/product/detail';
import Video_M from './pages/mobile/video'
import Privacy from "./components/privacy";

function App() {
    const [isMobile, setIsMobile] = useState(false);

    const ua = navigator.userAgent.toLowerCase();
    const agents = [
        'iphone',
        'ipad',
        'ipod',
        'android',
        'linux',
        'windows phone']; // 所有可能是移动端设备的字段

    useEffect(() => {
        for (let i = 0; i < agents.length; i++) {
            if (ua.indexOf(agents[i]) !== -1) {
                setIsMobile(true);
            }
        }
        return () => {
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {isMobile ? <HashRouter>
                <Switch>
                    <Route exact path={'/home'} component={Home_M}/>
                    <Route exact path={'/product/:type'} component={Product_M}/>
                    <Route exact path={'/productDetail'} component={ProductDetail_M}/>
                    <Route exact path={'/product'} component={Product_M}/>
                    <Route exact path={'/solutions/:type'} component={Platform_M}/>
                    <Route exact path={'/solutions'} component={Platform_M}/>
                    <Route exact path={'/cooperate/:type'} component={Cooperate_M}/>
                    <Route exact path={'/cooperate'} component={Cooperate_M}/>
                    <Route exact path={'/about/:type'} component={About_M}/>
                    <Route exact path={'/about'} component={About_M}/>
                    <Route exact path={'/join'} component={Join_M}/>
                    <Route exact path={'/platformDetail/:type'} component={PlatformDetail_M}/>
                    <Route exact path={'/video'} component={Video_M}/>
                    <Redirect to={'/home'}/>
                </Switch>
            </HashRouter> : <HashRouter>
                <Switch>
                    <Route exact path={'/home'} component={Home}/>
                    <Route exact path={'/product/:type'} component={Product}/>
                    <Route exact path={'/productDetail'} component={ProductDetail}/>
                    <Route exact path={'/product'} component={Product}/>
                    <Route exact path={'/platform'} component={Platform}/>
                    <Route exact path={'/cooperate/:type'} component={Cooperate}/>
                    <Route exact path={'/cooperate'} component={Cooperate}/>
                    <Route exact path={'/about/:type'} component={About}/>
                    <Route exact path={'/about'} component={About}/>
                    <Route exact path={'/join'} component={Join}/>
                    <Route exact path={'/news'} component={News}/>
                    <Route exact path={'/solutions/:type'} component={Solutions}/>
                    <Route exact path={'/solutions'} component={Solutions}/>
                    <Route exact path={'/news/detail'} component={NewsDetail}/>
                    <Route exact path={'/school/detail'} component={CooperateDetail}/>
                    <Route exact path={'/platformDetail/:type'} component={PlatformDetail}/>
                    <Route exact path={'/video'} component={Video}/>
                    <Route exact path={'/privacy'} component={Privacy}/>
                    <Redirect to={'/home'}/>
                </Switch>
            </HashRouter>}
        </>
    );
}

export default App;
