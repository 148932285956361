import React, {createRef, useEffect, useReducer} from 'react'
import {Modal} from "antd";
import {PlayCircleOutlined} from '@ant-design/icons'
import {Player} from 'video-react';
import style from './index.module.less'

const Video = (props) => {
    const playerRef = createRef()

    const initialState = {
        visible: false,
    };
    const reducer = (state, action) => {
        return {
            ...state,
            ...action,
        };
    };
    const [state, setState] = useReducer(reducer, initialState);
    const {visible} = state;
    const {title, desc, address, img} = props
    useEffect(() => {
        if (playerRef.current) {
            document.getElementsByClassName("video-react-big-play-button")[0].style.display = "none";
            playerRef.current.video.play()
        }
    }, [playerRef])

    const data = [{
        title: <div>{title}</div>,
        desc: <div>{desc}</div>,
        img: img,
    }]

    return <div className={style.banner_wrapper}>
        {data.map((ele) => (
            <div>
                <img src={ele.img}/>
                <div className={style.title_wrapper}>
                    <div className={style.title}>{ele.title}</div>
                    <div className={style.desc}>{ele.desc}</div>
                </div>
                <PlayCircleOutlined
                    className={style.play}
                    onClick={(e) => {
                        setState({
                            visible: true
                        })
                    }}
                />
            </div>
        ))}
        <Modal
            visible={visible}
            footer={false}
            width={1000}
            closable={false}
            onCancel={() => {
                playerRef.current.video.pause()
                setState({visible: false})
            }}
            wrapClassName={style.web}//对话框外部的类名，主要是用来修改这个modal的样式的
        >
            <Player
                autoPlay
                ref={playerRef}
                src={address}>
            </Player>
        </Modal>
    </div>
}

export default Video
