import React,{ useEffect, useState } from "react";
import style from './index.module.less'

const Video_M = () => {
    const [list, setList] = useState([])
    const getList = async () => {
        let response = await fetch('https://toplion-static.oss-cn-beijing.aliyuncs.com/productVideo/toplionVideo.json').then(response => response.json()).then(data => data)
        setList(response)
    }
    useEffect(() => {
        getList()
    }, [])
    return <>
    

    <div className={style.bg_wrapper}>
    <div className={style.title}>神州顶联视频库</div>
    <div className={style.under_line}></div>
    <div className={style.content_wrapper}>
    {list.map((elem) => {
                        return (
                            <>
                                <div className={style.text_wrapper} key={`title-${elem.text}`}>
                                    <div >
                                        <div className={style.text}>{elem.title}</div>
                                        {elem.child.map(item => {
                                            return (
                                                <>
                                                    <div className={style.url} key={`url-${item.url}`}>
                                                        {item.url ? <>                                                        
                                                        <a href={`https://toplion.com.cn/${elem.fold}/${item.url}.mp4`}
                                                            className={style.two_tab}>{item.title}</a></> 
                                                            : <> <div className={style.two_title}>{item.title}</div>
                                                            {item.child.map((e) => {
                                                                return (
                                                                    <><div>
                                                                        <a href={`https://toplion.com.cn/${elem.fold}/${e.url}.mp4`}
                                                                            className={style.two_tab}>{e.title}</a>
                                                                            </div>
                                                                    </>
                                                                )
                                                            })}
                                                        </>}

                                                    </div>
                                                </>
                                            )

                                        })}
                                    </div>
                                </div>
                            </>
                        )
                    })}
    </div>
</div>
</>
}
export default Video_M