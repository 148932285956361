import React, {useEffect} from 'react'
import styles from './index.module.less'
import ScrollReveal from "scrollreveal";

const PageTop_M = (props) => {
    useEffect(() => {
        let title = {
            distance: '100px',
            origin: 'bottom',
            reset: true,
        };
        ScrollReveal().reveal(`.title`, title);
        return ()=>{

        }
    }, [])
    return <div className={styles.top_wrapper}>
        <img src={props.bg}/>
        <div className={`${styles.info_wrapper} title`}>
            {props.title}
        </div>
        <div className={`${styles.titles} titles`}>
            {props.titles}
        </div>
        <div className={styles.gaishu}>
            {props.gaishu}
        </div>
        <div className={`${styles.titless} titless`}>
            {props.titless}
        </div>
        <div className={styles.gaishus}>
            {props.gaishus}
        </div>
    </div>
}
export default PageTop_M
