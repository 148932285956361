// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EVoHTSLJlzfY046L\\+SDXQg\\=\\= {\n  width: 1000px;\n  display: flex;\n  flex-wrap: wrap;\n  margin: 30px auto;\n}\n.EVoHTSLJlzfY046L\\+SDXQg\\=\\= .d5I2rTY6LCpii59Egn6YuA\\=\\= {\n  cursor: pointer;\n  text-align: left;\n  width: 300px;\n  margin-right: 50px;\n}\n.EVoHTSLJlzfY046L\\+SDXQg\\=\\= .d5I2rTY6LCpii59Egn6YuA\\=\\=:nth-child(3n) {\n  margin-right: 0;\n}\n.EVoHTSLJlzfY046L\\+SDXQg\\=\\= .d5I2rTY6LCpii59Egn6YuA\\=\\= img {\n  width: 100%;\n  height: 190px;\n  border-radius: 4px;\n}\n.EVoHTSLJlzfY046L\\+SDXQg\\=\\= .d5I2rTY6LCpii59Egn6YuA\\=\\= .Dtf3JrgUEytpfc3Z1\\+GxJQ\\=\\= {\n  font-size: 16px;\n  margin: 10px 0 5px;\n}\n.EVoHTSLJlzfY046L\\+SDXQg\\=\\= .d5I2rTY6LCpii59Egn6YuA\\=\\= .OuRQKgDQ8knUH\\+b0WNOfzw\\=\\= {\n  color: #666;\n}\n.EVoHTSLJlzfY046L\\+SDXQg\\=\\= .d5I2rTY6LCpii59Egn6YuA\\=\\= .qBNa8HcNtbqWGszBiBhcig\\=\\= {\n  width: 120px;\n}\n", "",{"version":3,"sources":["webpack://src/pages/pc/news/index.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,eAAA;EACA,iBAAA;AACF;AALA;EAOI,eAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;AACJ;AAAI;EACE,eAAA;AAEN;AAdA;EAgBM,WAAA;EACA,aAAA;EACA,kBAAA;AACN;AAnBA;EAsBM,eAAA;EACA,kBAAA;AAAN;AAvBA;EA2BM,WAAA;AADN;AA1BA;EA+BM,YAAA;AAFN","sourcesContent":[".news_wrapper {\n  width: 1000px;\n  display: flex;\n  flex-wrap: wrap;\n  margin: 30px auto;\n\n  .news_item {\n    cursor: pointer;\n    text-align: left;\n    width: 300px;\n    margin-right: 50px;\n    &:nth-child(3n) {\n      margin-right: 0;\n    }\n\n    img {\n      width: 100%;\n      height: 190px;\n      border-radius: 4px;\n    }\n\n    .title{\n      font-size: 16px;\n      margin: 10px 0 5px;\n    }\n\n    .date{\n      color: #666;\n    }\n\n    .more_wrapper{\n      width: 120px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"news_wrapper": "EVoHTSLJlzfY046L+SDXQg==",
	"news_item": "d5I2rTY6LCpii59Egn6YuA==",
	"title": "Dtf3JrgUEytpfc3Z1+GxJQ==",
	"date": "OuRQKgDQ8knUH+b0WNOfzw==",
	"more_wrapper": "qBNa8HcNtbqWGszBiBhcig=="
};
export default ___CSS_LOADER_EXPORT___;
