import React, {useEffect, useState} from 'react';
import Header from '../../../components/header';
import Bottom from '../../../components/contact';
import Reserve from '../../../components/reserve';
import BasicLayout from '../../../layout/basicLayout';
import {NEWS} from '../../../contant';
import Bread from '../../../components/bread';

const News = (props) => {
    const [news, setNews] = useState({})
    useEffect(() => {
        const {title} = props.location.query
        const news = NEWS.find(ele => ele.title === title)
        setNews(news)
    })
    return <BasicLayout>
        <Header fixed/>
        <div>
            <div style={{height: '100px'}}/>
            <Bread breads={[{name: '首页', href: '#/home'}, {name: '关于我们', href: '#/about'}, {
                name: '新闻中心',
                href: '#/news'
            }, {name: '新闻详情'},]}/>
            {news.content}
        </div>
        <Bottom/>
        <Reserve/>
    </BasicLayout>;
};
export default News;
