import {useHistory, useLocation} from 'react-router-dom';
import React, {createRef, useEffect, useState} from 'react';
import styles from './index.module.less';
import Header from '../../../comp-mobile/header';
import Footer from '../../../comp-mobile/footer';
import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Mousewheel,
} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
// 引入 Swiper 样式
import 'swiper/swiper.less';
import 'swiper/components/navigation/navigation.less';
import 'swiper/components/pagination/pagination.less';
import 'swiper/components/scrollbar/scrollbar.less';
import Module from '../../../comp-mobile/module';
import {
    CHANNEL_PRODUCTS_TEMPORARY, FUSION_GATEWAY, HOME_MAIN_BUSINESS,
    LOCK_PRODUCTS_TEMPORARY,
    platforms,
    WATER_SAVING_PRODUCTS_Temporary
} from '../../../contant';
import Solution_Module from "../../../comp-mobile/module/solution";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Mousewheel]);

const Home_M = (props) => {
    document.title = '神州顶联科技有限公司'
    const history = useHistory();
    const location = useLocation();
    const swiperRef = createRef()
    let swiperObj = null

    const datas = [{
        title: <div>
            <div>神州顶联<br/>校园数据智能践行者</div>
        </div>,
        desc: <div>智慧校园解决方案与投建运营服务商</div>,
        desc1: <div>以数据驱动校园业务重构，极大提升师生应用和服务体验</div>,
        img: '/img/mobile/m_banner01.jpg',
        to: '/about'
    }, {
        title: <div>
            <div>智慧后勤<br/>解决方案</div>
        </div>,
        desc: <div>基于智能终端的多场景一卡通行产品</div>,
        desc1: <div>融合各类智能终端设备和应用场景，以“管理＋服务+数据+创新”的设计思想<br/>驱动后勤业务流程重构及管理转段升级
        </div>,
        img: '/img/mobile/m_banner02.jpg',
        type: 'card',
        to: '/solutions/card'
    }, {
        title: <div>
            <div>智慧学工<br/>解决方案</div>
        </div>,
        desc: <div>面向精细化学生管理的全业务应用</div>,
        desc1: <div>以业务创新融合、精细化管理为目标，结合跨场景实时数据<br/>实现跨部门、跨系统、跨应用的创新型学工平台建设
        </div>,
        img: '/img/mobile/m_banner03.jpg',
        type: 'learn',
        to: '/solutions/learn'
    }]

    const platList = []
    for (let i = 0; i < platforms().length; i += 3) {
        platList.push(platforms().slice(i, i + 3));
    }
    const products = [
        {...FUSION_GATEWAY, type: 'gateway'},
        {...WATER_SAVING_PRODUCTS_Temporary[0], type: 'water_saving'},
        {...WATER_SAVING_PRODUCTS_Temporary[2], type: 'water_saving'},
        {...LOCK_PRODUCTS_TEMPORARY[0], type: 'door_lock'},
        {...CHANNEL_PRODUCTS_TEMPORARY[3], type: 'channel'},

    ]
    const partnerList = [
        {
            count: 300,
            unit: '所',
            title: '高校',
        }, {
            count: 300,
            unit: '万',
            title: '用户',
        }, {
            count: 100,
            unit: '项',
            title: '专利软著',
        }, {
            count: 50,
            unit: '家',
            title: '平台合作伙伴',
        }, {
            count: 10,
            unit: '家',
            title: '支付合作平台',
        }, {
            count: 200,
            unit: '家',
            title: '合作伙伴',
        }]
    const [curProItem, setCurProItem] = useState(0);

    useEffect(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0
    }, [props.location.state])
    return <div>
        <Header/>
        <div className={styles.container_wrapper}>
            <div className={styles.swiper_wrapper}>
                <Swiper
                    autoplay
                    ref={swiperRef}
                    slide={'fade'}
                    pagination={{clickable: true}}
                    onSwiper={swiper => (swiperObj = swiper)}
                >
                    {datas.map((ele, index) => (
                        <SwiperSlide
                            style={{cursor: `${index ? 'pointer' : ''}`}}
                            key={`swiper${index}`} onClick={() => {
                            ele.to && history.replace({
                                pathname: `${ele.to}`,
                                state: {type: ele.type},
                            });
                        }}>
                            <img style={{width: '100%'}} src={ele.img}/>
                            <div
                                className={`${index === 2 || index === 0 ? styles.info_wrapper2 : styles.info_wrapper}`}>
                                <div className={styles.title}>{ele.title}</div>
                                <div className={styles.sub_title}>{ele.desc}</div>
                                <div className={styles.sub_title1}>{ele.desc1}</div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            <div className={styles.content_wrapper}>
                <Solution_Module title={'主营业务'} bg={'/img/solution/common/module_bg/01.png'} isTop={true}>
                    <div className={styles.other_solutions_wrapper}>
                        {HOME_MAIN_BUSINESS.map(
                            (item, index) => {
                                return <div key={`scene_item${index}`}
                                            onClick={() => {
                                                props.history.replace({
                                                    pathname: `${item.to}`,
                                                    state: {type: item.type},
                                                });
                                            }}
                                            className={`${styles.scene_item} scene_item${index}`}>
                                    <img className={'img_bg'}
                                         src={item.img}/>
                                    <div className={styles.info_wrapper}>
                                        <div>{item.title}</div>
                                    </div>
                                </div>;
                            })}
                    </div>
                    <div onClick={() => {
                        history.push('/platform')
                    }} className={styles.btn}>查看更多服务...
                    </div>
                </Solution_Module>
                <Solution_Module title={'热销产品'} bg={'/img/solution/common/module_bg/04.png'}>
                    <div className={styles.product_wrapper}>
                        <div className={styles.IOT_tab_wrapper}>
                            {products.map((ele, index) => (
                                <div key={`product${index}`} onClick={() => {
                                    setCurProItem(index);
                                }} className={`${styles.IOT_tab_item} ${curProItem ===
                                index ? styles.selected : ''}`}>
                                    {ele.titless}
                                </div>
                            ))}
                        </div>

                        <div className={styles.platform_intro_wrapper}>
                            <div className={styles.info_wrapper}>
                                {products[curProItem].type !== 'gateway' ? <div onClick={() => {
                                    history.push({
                                        pathname: '/productDetail',
                                        query: {title: products[curProItem].title, type: products[curProItem].type}
                                    })
                                }}>
                                    <div className={styles.title}>{products[curProItem].title}
                                        <div className={styles.jiantou}>
                                            <img src={'/img/jiantou.png'}/>
                                        </div>
                                    </div>
                                </div> : <div className={styles.gateway}>{products[curProItem].title}</div>
                                }
                                <div className={styles.explain}>{products[curProItem].detail.scene}</div>
                                <div className={styles.explain_wrapper}>
                                    {
                                        products[curProItem].detail.teses.map((ele, index) => (
                                            <div key={`explain${index}`} className={styles.explain_item_wrapper}>
                                                <div className={styles.explain_item}>
                                                    <img src={ele.icon} className={styles.icon_img}/>
                                                    <span className={styles.desc}>{ele.content}</span>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <img src={products[curProItem].imgs} className={styles.info_img}/>
                        </div>
                    </div>
                </Solution_Module>

                <Solution_Module
                    title={'平台及服务'}
                    bg={'/img/solution/common/module_bg/01.png'}
                >
                    <div className={styles.services}>
                        <img src={'/img/pingtaijifuwu_home.png'}/>
                    </div>
                </Solution_Module>

                <Module title={'生态伙伴'} eng={'Ecological Partnership'} titlestyle={{color: 'white'}}
                        titleengstyle={{color: 'white'}}
                        bg={'/img/partner_bg.png'}>
                    <div className={styles.partner_wrapper} style={{paddingLeft: '15%'}}>
                        {partnerList.map((ele, index) => (
                            <div key={`partner${index}`} className={styles.partner_item}>
                                <div className={styles.count_wrapper}>
                                    <span className={styles.count}>{ele.count}</span>
                                    <span className={styles.unit}>{ele.unit}</span>
                                    <span className={styles.plus}>+</span>
                                </div>
                                <div className={styles.line}/>
                                <div className={styles.item_type}>{ele.title}</div>
                            </div>
                        ))}
                    </div>
                </Module>

                {/*<Module title={'公司简介'} eng={'About Toplion'}*/}
                {/*        style={{backgroundColor: '#F8F8F8', paddingBottom: '4px'}}/>*/}
                {/*<div className={styles.company_content}>*/}
                {/*    <img className={styles.comp_img} src={'/img/toplion02.png'}/>*/}
                {/*    <div className={styles.description}>*/}
                {/*        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;神州顶联科技有限公司（简称:神州顶联）成立于2007年，神州数码集团股份有限公司教育业务核心企业。秉持“以数据驱动业务重构，极大提升用户应用体验”的服务理念，积极推动中国校园智能化跨越式发展，致力于成为校园客户最信赖的合作伙伴。<br/>*/}
                {/*        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;作为行业领先的智慧校园解决方案与投建运营服务商，创新B2B2C（学校-渠道商-用户）商业模式，围绕“教、学、研、管、服、评”6个教育数字化场景，结合自研智能感知终端，打造了“智慧网络、融合网关、智慧门禁、智慧生活、智慧零售、一校通、智慧学工、智慧后勤、AI校园、数据治理”十大解决方案，深化一终端多能，一数据多用，一网络通行，一平台通办、一校园智安的“五个一”校园数据治理理念，为处在不同阶段的各类院校提供基础设施升级和数字驱动服务，全面推动校园数字化、智能化转型服务。<br/>*/}
                {/*        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;未来，神州顶联将继续深耕智慧校园市场，持续加大产品研发与创新；把用户服务放在第一位，完善服务体系，为用户提供高品质应用体验；坚持开放、共享、共赢的原则，构建优势互补、可持续发展的生态体系。为推动中国教育高质量发展贡献一份力量！<br/>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <Footer/>
            </div>
        </div>
    </div>;
};

export default Home_M;
