import {useHistory, useLocation} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import styles from './index.module.less';
import {Drawer, Collapse} from 'antd';
import {drawerData_Temporary} from '../../contant';

const Header = (props) => {
    const [whiteHeader, setWhiteHeader] = useState(false);
    const [visible, setVisible] = useState(false);
    const [widthVisible, setWidthVisible] = useState('none');
    const [widthVisibleIcon, setWidthVisibleIcon] = useState('icon-menu-show');
    const [current, setCurrent] = useState(0);
    const history = useHistory();
    const location = useLocation();
    const {Panel} = Collapse;
    const [clickHome, setClickHome] = useState(location.pathname === '/home');

    useEffect(() => {
            if (props.fixed) {
                setWhiteHeader(true);
            } else {
                window.onscroll = (
                    ev => {
                        scrollEvent();
                    });
            }
            const product = document.getElementById('/product');
            const solution = document.getElementById('/solutions/net');
            const cooperate = document.getElementById('/cooperate');
            const about = document.getElementById('/about');
            const d = document.getElementById('drawer');

            solution.onmouseover = (e) => {
                if (!visible) {
                    setVisible(true);
                    setCurrent(0);
                }
            };
            solution.onmouseout = (e) => {
                setVisible(false);
            };
            product.onmouseover = (e) => {
                if (!visible) {
                    setVisible(true);
                    setCurrent(1);
                }
            };
            product.onmouseout = (e) => {
                setVisible(false);
            };
            cooperate.onmouseover = (e) => {
                if (!visible) {
                    setVisible(true);
                    setCurrent(2);
                }
            };
            cooperate.onmouseout = (e) => {
                setVisible(false);
            };
            about.onmouseover = (e) => {
                if (!visible) {
                    setVisible(true);
                    setCurrent(3);
                }
            };
            about.onmouseout = (e) => {
                setVisible(false);
            };
            d.onmouseover = (e) => {
                !visible && setVisible(true);
            };
            d.onmouseout = (e) => {
                setVisible(false);
            };
        }, [],
    );

    const scrollEvent = () => {
        const scrollTop = document.documentElement.scrollTop ||
            document.body.scrollTop;
        if (scrollTop > 700) {
            setWhiteHeader(true);
        } else {
            setWhiteHeader(false);
        }
    };


    const tabClick = (route) => {
        history.push(route);
        document.documentElement.scrollTop = 0;
    };

    const tabs = [
        {
            title: '首页',
            router: '/home',
        }, {
            title: '解决方案',
            router: '/solutions/net',
        }, {
            title: '产品',
            router: '/product',
        }, {
            title: '商业合作',
            router: '/cooperate',
        }, {
            title: '关于我们',
            router: '/about',
        }, {
            title: '招贤纳士',
            router: '/join',
        }];

    const homeDrawerStyle = {boxShadow: 'none'};
    const otherDrawerStyle = {marginTop: '91px', boxShadow: 'none'};

    const titleClick = (ele) => {
        if (ele.to) {
            history.push(ele.to);
        }
    };

    const widthVisibleClick = () => {
        setWidthVisible(`${widthVisible === 'none' ? 'block' : 'none'}`)
        setWidthVisibleIcon(`${widthVisibleIcon === 'icon-close' ? 'icon-menu-show' : 'icon-close'}`)
    }
    const renderDrawer = () => {
        return <div className={styles.drawer_container}>
            <div className={styles.drawer_wrapper}
                 style={drawerData_Temporary[current]?.style} id={'drawer'}>
                {drawerData_Temporary[current]?.type === 'solution' ? (
                        drawerData_Temporary[current].children.map((ele, index) => {
                            return <div className={styles.solution_item_wrapper} key={`solution${index}`}>
                                <div className={styles.solution_item_title}>{ele.title}</div>
                                <div>
                                    {ele.children.map(e => {
                                        return <div className={styles.item_wrapper} key={`solution${index}-${e.title}`}
                                                    onClick={() => {
                                                        setVisible(false);
                                                        history.replace({
                                                            pathname: `${e.to}`,
                                                            state: {type: e.type},
                                                        });
                                                    }}
                                        >
                                            <div style={{marginRight: 5}}><img src={e.icon}/></div>
                                            <div>{e.title}</div>
                                        </div>
                                    })}
                                </div>
                            </div>;
                        })
                    )
                    : (drawerData_Temporary[current].type === 'product') ?
                        (drawerData_Temporary[current].children.map((ele, index) => {
                            return <div key={`drawer${index}`} style={ele.style}
                                        className={styles.drawer_module_product}
                            >
                                <div className={`${ele.children
                                    ? styles.module_title
                                    : styles.module_title_no}`}
                                     onClick={() => {
                                         titleClick(ele);
                                     }}><img src={ele.icon}/>{ele.title}</div>
                                {ele.children && ele.children.map((item, j) => {
                                    return <div className={styles.title}
                                                onClick={() => {
                                                    history.replace({
                                                        pathname: '/productDetail',
                                                        query: {title: item.title, type: ele.type}
                                                    })
                                                }}
                                                key={`drawerData${j}`}>{item.title}</div>;
                                })}
                            </div>;
                        })) : (drawerData_Temporary[current].type === 'cooperate') ?
                            (drawerData_Temporary[current].children.map((ele, index) => {
                                    return <div key={`drawer${index}`} style={ele.style}
                                                className={styles.cooperate_item_wrapper}
                                    >
                                        <div onClick={() => {
                                            titleClick(ele);
                                        }}><img src={ele.icon}/>{ele.title}</div>
                                        {
                                            ele.children && ele.children.map((item, j) => {
                                                return <div className={styles.title}
                                                            onClick={() => {
                                                                history.push(item.to);
                                                            }}
                                                            key={`drawerData${j}`}>{item.title}</div>;
                                            })
                                        }
                                    </div>;
                                })
                            )
                            : (drawerData_Temporary[current].type === 'about') ?
                                (drawerData_Temporary[current].children.map((ele, index) => {
                                        return <div key={`drawer${index}`} style={ele.style}
                                                    className={styles.about_item_wrapper}
                                        >
                                            <div onClick={() => {
                                                titleClick(ele);
                                            }}><img src={ele.icon}/>{ele.title}</div>
                                            {
                                                ele.children && ele.children.map((item, j) => {
                                                    return <div className={styles.title}
                                                                onClick={() => {
                                                                    history.push(item.to);
                                                                }}
                                                                key={`drawerData${j}`}>{item.title}</div>;
                                                })
                                            }
                                        </div>;
                                    })
                                ) : ''
                }
            </div>
        </div>;
    };
    return <div className={`${styles.header_wrapper} ${whiteHeader
        ? styles.header_wrapper_white
        : ''} ${current === 0 ? styles.solution : current === 1 ? styles.product : current === 2 ? styles.cooperate : current === 3 ? styles.about : ''}`}
    >
        <img src={whiteHeader ? '/img/logo_color.png' : '/img/logo@2x.png'}
             className={styles.logo} onClick={() => tabClick('/home')}/>
        <Drawer
            placement="top"
            getContainer={false}
            closable={false}
            visible={visible}
            zIndex={-10}
            height={current === 1 ? 474 : current === 0 ? 300 : 260}
            maskStyle={{backgroundColor: 'transParent'}}
            contentWrapperStyle={!visible ? homeDrawerStyle : otherDrawerStyle}
        >
            {renderDrawer()}
        </Drawer>
        <Collapse accordion expandIconPosition={'right'} className={styles.CollapseStyle}
                  style={{display: `${widthVisible}`}}>
            {tabs.map((ele, index) => {
                if (index == 0) {
                    return <div className={styles.collapseHome} onClick={() => {
                        tabClick(ele.router)
                    }}>首页</div>
                } else if (index == 5) {
                    return <div className={styles.collapseHome} onClick={() => {
                        tabClick(ele.router)
                    }}>招贤纳士</div>
                } else {
                    return <Panel header={<div onClick={() => {
                        tabClick(ele.router)
                    }}>{ele.title}</div>} key={index}>
                        <Collapse accordion bordered={false}>
                            {drawerData_Temporary[index - 1].children.map((ele, i) => {
                                if (ele.children) {
                                    return <Panel header={ele.title}>
                                        {ele.children.map((ele, index) => {
                                            return <div className={styles.collapseHome} onClick={() => {
                                                tabClick(ele.to)
                                            }}>{ele.title}</div>
                                        })}
                                    </Panel>
                                } else {
                                    return <div className={styles.collapseHome} onClick={() => {
                                        ele.to ? tabClick(ele.to) : history.replace({
                                            pathname: `${ele.to}`,
                                            state: {type: ele.type},
                                        });
                                    }}>{ele.title}</div>
                                }
                            })}
                        </Collapse>
                    </Panel>
                }
            })}
        </Collapse>
        <div className={styles.tab_wrapper}>
            {tabs.map((ele, index) => {
                return <div key={`header${index}`}
                            className={styles.tab_item} onClick={() => {
                    tabClick(ele.router);
                }}>
                    <div id={ele.router} key={`name-${ele.router}-${index}`}>
                        {ele.title}
                        {index !== 0 && index !== 5 ?
                            <img
                                id={`tab_item_icon_${index}`}
                                className={styles.tab_item_icon}
                                src={clickHome ? ((visible && index === current + 1) ? '/img/solution/common/header_icon/up_white_icon.png'
                                        : '/img/solution/common/header_icon/down_white_icon.png') :
                                    (visible && index === current + 1 ? '/img/solution/common/header_icon/up_icon.png' : '/img/solution/common/header_icon/down_icon.png')}
                            /> : <span className={styles.tab_item_icon}>&nbsp;&nbsp;</span>}
                    </div>
                    {location.pathname.indexOf(ele.router) > -1 ? <div
                        className={styles.line}/> : null}
                </div>
            })}
        </div>
        <svg className={styles.icon} aria-hidden={"true"} onClick={widthVisibleClick}
             fill={whiteHeader ? '#177fdd' : 'white'}>
            <use xlinkHref={`#${widthVisibleIcon}`}></use>
        </svg>
    </div>;
};

export default Header;
