import React, {useEffect, useState} from 'react'
import styles from './index.module.less'
import Header from '../../../components/header'
import Bottom from '../../../components/contact'
import Reserve from '../../../components/reserve'
import BasicLayout from '../../../layout/basicLayout'
import PageTop from '../../../components/pageTop'
import Module from '../../../components/module'
import 'swiper/swiper.less'
import SwiperCore, {Autoplay} from 'swiper'
import {drawerData_Temporary,} from '../../../contant';

SwiperCore.use([Autoplay])

const Product = (props) => {
    const [visible, setVisible] = useState(false)
    const [type, setType] = useState('water_saving')

    const $ = (id) => {
        return typeof (id) === "string" ? document.getElementById(id) : id
    }

    const mouse = (type) => {
        $(`${type}`).onmouseover = (e) => {
            if (!visible) {
                setVisible(true);
                setType(`${type}`);
            }
        };
        $(`${type}`).onmouseout = (e) => {
            setVisible(false);
        };
    }

    const current_product = drawerData_Temporary[1].children.find(item => item.type === type)


    useEffect(() => {
        init()
        drawerData_Temporary[1].children.forEach((item) => {
            mouse(item.type)
        })
    }, [])

    const init = () => {
        let index = props.match.params.type
        if (index) {
            index = parseInt(index)
            if (index < 8) {
                let anchorElement = document.getElementById('Smart_Life')
                if (anchorElement) {
                    anchorElement.scrollIntoView()
                }
            } else {
                let anchorElement = document.getElementById('Security')
                if (anchorElement) {
                    anchorElement.scrollIntoView()
                }
            }
        }
    }

    useEffect(() => {
        init()
    }, [props.match.params.type])

    return <BasicLayout>
        <Header fixed/>
        <div className={'content_wrapper'}>
            <PageTop bg={'/img/banner_product.png'} title={'万物互联，开启校园智慧生活时代'}/>
            <Module title={'智能硬件终端'} eng={'Intelligent hardware'}>
                <div className={styles.pro_nav_wrapper}>
                    <ul>{drawerData_Temporary[1].children.map(item =>
                        <li id={item.type}
                            className={`${type === item.type ? styles.selected : ''}`}>{item.title}</li>)}
                    </ul>
                    <div className={styles.smart_life_wrapper}>
                        {current_product.children.map(ele => {
                            return <div key={ele.title} className={styles.smart_life_item_wrapper}>
                                <img src={ele.img}/>
                                <div className={styles.product_info_wrapper}>
                                    <div className={styles.title}>{ele.title}</div>
                                </div>
                                <div className={styles.detail_btn} onClick={() => {
                                    props.history.push({
                                        pathname: '/productDetail',
                                        query: {title: ele.title, type: type}
                                    })
                                }}>了解详情
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </Module>
        </div>
        <Bottom/>
        <Reserve/>
    </BasicLayout>
}
export default Product
