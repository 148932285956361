import {useHistory} from 'react-router-dom';
import React, {useEffect} from 'react';
import styles from './index.module.less';
import {MenuFoldOutlined} from '@ant-design/icons';
import {Menu, Popover} from 'antd';

const Header = (props) => {

    const history = useHistory();

    useEffect(() => {
            setTimeout(() => {
                window.scrollTo(0, 0);
            });
            return () => {

            };
        }, [],
    );

    const menuList = [
        {
            title: '首页',
            router: '/home',
        }, {
            title: '解决方案',
            router: '/solutions/net',
            type:'net'
        }, {
            title: '产品',
            router: '/product',
        }, {
            title: '商业合作',
            router: '/cooperate',
        }, {
            title: '关于我们',
            router: '/about',
        }, {
            title: '加入我们',
            router: '/join',
        }];
    const menu = (
        <Menu>
            {
                menuList.map((ele, index) => {
                    return <Menu.Item key={`menu${index}`}>
                        <a onClick={() => {
                        ele.title === '解决方案' ? history.replace({
                            pathname: `${ele.router}`,
                            state: {type: ele.type},
                        }):  history.replace({
                            pathname: `${ele.router}`,
                        })
                        }}>
                            {ele.title}
                        </a>
                    </Menu.Item>;
                })
            }
        </Menu>
    );
    return <div className={`${styles.header_wrapper}`}>
        <img src={'/img/logo_color.png'} onClick={() => {history.push('/');}}
             className={styles.logo}/>
        <Popover trigger={'click'} placement="bottomRight" content={menu}>
            <div className={styles.menu_icon}>
                <MenuFoldOutlined style={{fontSize: '20px'}}/>
            </div>
        </Popover>

    </div>;
};

export default Header;
