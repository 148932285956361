// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s2EUJ9skgfuCfMebnn1H7Q\\=\\= {\n  padding: 20px;\n}\n.s2EUJ9skgfuCfMebnn1H7Q\\=\\= .mmbhr4EPm0z9bd8Nzk0G8A\\=\\= {\n  font-size: 20px;\n  font-weight: bold;\n  text-align: center;\n  margin-bottom: 20px;\n}\n.s2EUJ9skgfuCfMebnn1H7Q\\=\\= ._8omTdbOTkKYznTfuqBKSew\\=\\= {\n  white-space: pre-wrap;\n}\n", "",{"version":3,"sources":["webpack://src/components/privacy/index.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAFA;EAII,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;AACJ;AARA;EAWI,qBAAA;AAAJ","sourcesContent":[".wrapper {\n  padding: 20px;\n\n  .title {\n    font-size: 20px;\n    font-weight: bold;\n    text-align: center;\n    margin-bottom: 20px;\n  }\n\n  .content {\n    white-space: pre-wrap;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "s2EUJ9skgfuCfMebnn1H7Q==",
	"title": "mmbhr4EPm0z9bd8Nzk0G8A==",
	"content": "_8omTdbOTkKYznTfuqBKSew=="
};
export default ___CSS_LOADER_EXPORT___;
