import React, { useEffect } from 'react';
import styles from './index.module.less';
import Module from '../../../comp-mobile/module';
import Footer from '../../../comp-mobile/footer';
import PageTop_M from '../../../comp-mobile/pageTop';
import Header from '../../../comp-mobile/header';
import { Timeline } from 'antd';
import 'swiper/swiper.less';
import 'swiper/components/navigation/navigation.less';
import 'swiper/components/pagination/pagination.less';
import 'swiper/components/scrollbar/scrollbar.less';
import SwiperCore, {
    A11y,
    Navigation,
    Pagination,
    Scrollbar,
    Mousewheel,
    Virtual,
    Autoplay,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ALL_COMPANYS, timeLine } from '../../../contant';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Mousewheel, Virtual, Autoplay]);

const About_M = (props) => {
    document.title = '关于我们'
    useEffect(() => {
        const { type } = props.match.params;
        if (type) {
            let anchorElement = document.getElementById(type);
            if (anchorElement) {
                anchorElement.scrollIntoView();
            }
        }
    }, []);

    useEffect(() => {
        const { type } = props.match.params;
        if (type) {
            let anchorElement = document.getElementById(type);
            if (anchorElement) {
                anchorElement.scrollIntoView();
            }
        }
    }, [props.match.params]);
    const introList =
    {
        img: '/img/toplion01.png',
        text:
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;神州顶联科技有限公司成立于2007年，是神州数码集团教育板块布局企业。总部位于山东济南，现设有北京、吉林、山西、广州、安徽等多家子分公司。秉持“以实时数据驱动业务重构，极大提升用户应用体验”的理念，积极推动中国校园智能化跨越式发展，致力成为校园客户最信赖的合作伙伴。<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;作为行业领先的智慧校园解决方案与投建运营服务商，神州顶联始终以校园需求为导向，以师生体验为核心，以技术创新为引擎。围绕“教、学、研、管、服、评”等校园数字化场景，结合各种智能感知终端，打造校园网络、智慧生活、智慧后勤、智慧学工多种产品和解决方案，提供面向学生的校园消费服务和面向学校的数据服务。<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;历经十七年的沉淀与探索，已在全国范围内建立完善的服务网络。先后与中国科学技术大学、沈阳理工大学、太原师范学院、山东建筑大学等300余所校园建立长期合作关系，为300余万师生提供智能、高效、优质的校园服务。<br />
            </div>
    };

    const honors = [
        '/img/honor2.png',
        '/img/honor3.png',
        '/img/honor4.png',
        '/img/honor5.png',
        '/img/honor6.png'];
    return <div className={styles.container_wrapper}>
        <Header />
        <PageTop_M bg={'/img/about_bg.png'} title={`行业领先的智慧校园解决方案与投建运营服务商`} />
        <div className={styles.content_wrapper}>
            <Module id={'intro'} title={'公司简介'} eng={'About Toplion'}>
                <img src={introList.img} style={{ width: '100%' }} />
                <div style={{
                    marginTop: '10px',
                    color: '#666',
                }}>{introList.text}</div>
            </Module>
            <Module id={'history'} hidetitle bg={'/img/partner_bg.png'}
                style={{ paddingTop: '80px' }}>
                <Timeline>
                    {timeLine.map((ele, index) => {
                        return <Timeline.Item color={'gray'}
                            key={`time${index}`}>
                            <div
                                className={`${styles.timeLine_wrapper} time${index}`}>
                                <div
                                    className={`${styles.info_wrapper} ${styles.pr}`}>
                                    <div className={styles.title}>
                                        {ele.date}
                                    </div>
                                    <div className={styles.desc}>
                                        {ele.desc}
                                    </div>
                                </div>
                                <img className={styles.info_img}
                                    src={ele.img} />
                            </div>
                        </Timeline.Item>;
                    })}
                </Timeline>
            </Module>

            <Module id={'honor'} title={'荣誉资质'} eng={'Honor and Qualification'}
                style={{ backgroundColor: '#f4f8fc' }}>
                <div className={styles.honor_wrapper}>
                    <Swiper
                        // autoplay
                        centeredSlides
                        spaceBetween={10}
                        loop
                        slidesPerView={2}
                    >
                        {honors.map((ele, index) => {
                            return <SwiperSlide
                                key={`swiper${index}`}
                            >
                                <img className={styles.honor_img}
                                    src={ele} />
                            </SwiperSlide>;
                        })}
                    </Swiper>
                </div>
            </Module>

            <Module id={'contact'} title={'联系我们'} eng={'Contact Us'}>
                <SubCompany />
            </Module>
            <Footer />
        </div>
    </div>
};
export default About_M;

const SubCompany = (props) => {
    return <div className={styles.sub_company_wrapper}>
        {
            ALL_COMPANYS.map(ele => {
                return <div key={ele.mobile}
                    className={styles.sub_company_item_wrapper}>
                    <div className={styles.title}>
                        {ele.area}
                    </div>
                    <div className={styles.info_line}>
                        <div className={styles.img_wrapper}>
                            <img src={'/img/location.png'} />
                            地址:
                        </div>
                        <div className={styles.info_wrapper}>{ele.address}</div>
                    </div>
                    <div className={styles.info_line}>
                        <div className={styles.img_wrapper}>
                            <img src={'/img/mobile.png'} />
                            电话:
                        </div>
                        <div className={styles.info_wrapper}>{ele.mobile}</div>
                    </div>
                </div>;
            })
        }
    </div>;
};
