import React from 'react'
import styles from './solution.module.less'

const Solution_Module = (props) => {
    const {bg, title, isFirst, children} = props
    return <div className={styles.module_wrapper} {...props}>
        {bg ? <img className={styles.bg} src={bg}/> : null}
        <div className={styles.module_header} style={isFirst ? {marginTop: '5%'} : null}>
            <span className={styles.title}>
                {title}
            </span>
        </div>
        {children}
    </div>
}

export default Solution_Module
