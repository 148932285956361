// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nLOTrFVthdwopXCQlTJBDw\\=\\= {\n  padding: 104px 0;\n}\n.nLOTrFVthdwopXCQlTJBDw\\=\\= .ant-modal-content {\n  position: relative;\n  background-color: #00000000 !important;\n  border: 0;\n  border-radius: 4px;\n  background-clip: padding-box;\n  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;\n}\n.nLOTrFVthdwopXCQlTJBDw\\=\\= .ant-modal-body {\n  padding: 0 !important;\n  font-size: 0 !important;\n  line-height: 1 !important;\n}\n.OnoOzT1OC1tZOEN76P8eFQ\\=\\= {\n  height: 56rem;\n  position: absolute;\n  top: 33%;\n  margin: auto;\n  left: 40%;\n  z-index: 2;\n  font-size: 10rem;\n  opacity: 0.5;\n}\n", "",{"version":3,"sources":["webpack://src/components/videoPlayer/index.module.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAFA;EAIQ,kBAAA;EACA,sCAAA;EACA,SAAA;EACA,kBAAA;EACA,4BAAA;EACA,6CAAA;AACR;AAVA;EAaQ,qBAAA;EACA,uBAAA;EACA,yBAAA;AAAR;AAIA;EACI,aAAA;EACA,kBAAA;EACA,QAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;EACA,gBAAA;EACA,YAAA;AAFJ","sourcesContent":[".web {\n    padding: 104px 0;\n\n    :global(.ant-modal-content) {\n        position: relative;\n        background-color: #00000000 !important;\n        border: 0;\n        border-radius: 4px;\n        background-clip: padding-box;\n        box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;\n    }\n\n    :global(.ant-modal-body) {\n        padding: 0 !important;\n        font-size: 0 !important;\n        line-height: 1 !important;\n    }\n}\n\n.play {\n    height: 56rem;\n    position: absolute;\n    top: 33%;\n    margin: auto;\n    left: 40%;\n    z-index: 2;\n    font-size: 10rem;\n    opacity: 0.5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"web": "nLOTrFVthdwopXCQlTJBDw==",
	"play": "OnoOzT1OC1tZOEN76P8eFQ=="
};
export default ___CSS_LOADER_EXPORT___;
