import React, { useEffect } from 'react';
import styles from './index.module.less';
import ScrollReveal from 'scrollreveal';
import Header from '../../../components/header';
import Bottom from '../../../components/contact';
import Reserve from '../../../components/reserve';
import BasicLayout from '../../../layout/basicLayout';
import PageTop from '../../../components/pageTop';
import Module from '../../../components/module';
import { Timeline } from 'antd';
import 'swiper/swiper.less';
import 'swiper/components/navigation/navigation.less';
import 'swiper/components/pagination/pagination.less';
import 'swiper/components/scrollbar/scrollbar.less';
import SwiperCore, {
    A11y,
    Navigation,
    Pagination,
    Scrollbar,
    Mousewheel,
    Virtual,
    Autoplay,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ALL_COMPANYS, timeLine } from '../../../contant';
import { seat } from "../../..//utils/index.js";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Mousewheel, Virtual, Autoplay]);
const timeData = [...timeLine];
const About = (props) => {

    useEffect(() => {
        const { type } = props.match.params;
        if (type) {
            let anchorElement = document.getElementById(type);
            if (anchorElement) {
                anchorElement.scrollIntoView();
            }
        }
        timeData.forEach((ele, index) => {
            let scene_item = {
                distance: '200px',
                origin: ele.position === 'right' ? 'left' : 'right',
                reset: true,
                delay: 50,
            };
            ScrollReveal().reveal(`.time${index}`, scene_item);
        });
        let img1 = {
            distance: '200px',
            origin: 'right',
            reset: true,
            delay: 50,
        };
        ScrollReveal().reveal(`.img1`, img1);
        let img0 = {
            distance: '200px',
            origin: 'left',
            reset: true,
            delay: 50,
        };
        ScrollReveal().reveal(`.img0`, img0);
        return () => {
        }
    }, []);

    useEffect(() => {
        const { type } = props.match.params;
        if (type) {
            let anchorElement = document.getElementById(type);
            if (anchorElement) {
                anchorElement.scrollIntoView();
            }
        }
    }, [props.match.params]);

    const honors = [
        '/img/honor2.png',
        '/img/honor3.png',
        '/img/honor4.png',
        '/img/honor5.png',
        '/img/honor6.png'];
    return <BasicLayout>
        <Header fixed />
        <div className={'content_wrapper'}>
            <PageTop bg={'/img/about_bg.png'} title={`行业领先的智慧校园解决方案与投建运营服务商`} />
            <div className={styles.introduce}>
                <div className={styles.content}>
                    <div className={styles.left_intro}>
                        <div className={styles.left_intro_text}>历经十七年的沉淀与探索，已在全国范围内建立完善的服务网络。先后与中国科学技术大学、沈阳理工大学、太原师范学院、山东建筑大学等300余所校园建立长期合作关系，为300余万师生提供智能、高效、优质的校园服务。</div>
                    </div>
                    <div className={styles.right_intro}>
                        <div className={styles.jianjie}>
                            <div>公司简介</div>
                            <div className={styles.jianjie_en}>About Toplion</div>
                            <div className={styles.jianjie_content}>
                                神州顶联科技有限公司成立于2007年，是神州数码集团教育板块布局企业。总部位于山东济南，现设有北京、吉林、山西、广州、安徽等多家子分公司。秉持“以实时数据驱动业务重构，极大提升用户应用体验”的理念，积极推动中国校园智能化跨越式发展，致力成为校园客户最信赖的合作伙伴。<br /><br />
                                作为行业领先的智慧校园解决方案与投建运营服务商，神州顶联始终以校园需求为导向，以师生体验为核心，以技术创新为引擎。围绕“教、学、研、管、服、评”等校园数字化场景，结合各种智能感知终端，打造校园网络、智慧生活、智慧后勤、智慧学工多种产品和解决方案，提供面向学生的校园消费服务和面向学校的数据服务。<br /><br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Module id={'history'} hidetitle bg={'/img/partner_bg.png'}
                style={{ paddingTop: '80px' }}>
                <Timeline mode="alternate">
                    {
                        timeData.map((ele, index) => {
                            return <Timeline.Item color={'gray'}
                                key={`time${index}`}
                                position={ele.position}>
                                {ele.position === 'left' ? <div
                                    className={`${styles.timeLine_wrapper} time${index}`}>
                                    <div
                                        className={`${styles.info_wrapper} ${styles.pr}`}>
                                        <div className={styles.title}>
                                            {ele.date}
                                        </div>
                                        <div className={styles.desc}>
                                            {ele.desc}
                                        </div>
                                    </div>
                                    <img className={styles.info_img}
                                        src={ele.img} />
                                </div> : <div
                                    className={`${styles.timeLine_wrapper} time${index}`}>
                                    <img className={styles.info_img}
                                        src={ele.img} />

                                    <div
                                        className={`${styles.info_wrapper} ${styles.pl}`}>
                                        <div className={styles.title}>
                                            {ele.date}
                                        </div>
                                        <div className={styles.desc}>
                                            {ele.desc}
                                        </div>
                                    </div>

                                </div>}
                            </Timeline.Item>;
                        })
                    }
                </Timeline>
            </Module>

            <Module id={'honor'} title={'荣誉资质'} eng={'Honor and Qualification'}
                style={{ backgroundColor: '#f4f8fc' }}>
                <div className={styles.honor_wrapper}>
                    <Swiper
                        grabCursor
                        autoplay
                        centeredSlides
                        spaceBetween={40}
                        loop
                        slidesPerView={4}
                    >
                        {honors.map((ele, index) => {
                            return <SwiperSlide width={600}
                                key={`swiper${index}`}>
                                <img className={styles.honor_img}
                                    src={ele} />
                            </SwiperSlide>;
                        })
                        }
                    </Swiper>
                </div>
            </Module>

            <Module id={'contact'} title={'联系我们'} eng={'Contact Us'}>
                {seat(ALL_COMPANYS, 3).map(item => {
                    return <SubCompany array={item} />
                })}
            </Module>
        </div>
        <Bottom />
        <Reserve />
    </BasicLayout>;
};
export default About;

const SubCompany = (props) => {
    return <div className={styles.sub_company_wrapper}>
        {
            props?.array?.map(ele => {
                return <div key={ele.mobile}
                    className={styles.sub_company_item_wrapper}>
                    <div className={styles.title}>
                        {ele.area}
                    </div>
                    <div className={styles.info_line}>
                        <div className={styles.img_wrapper}>
                            <img src={'/img/location.png'} />
                            地址:
                        </div>
                        <div className={styles.info_wrapper}>{ele.address}</div>
                    </div>
                    <div className={styles.info_line}>
                        <div className={styles.img_wrapper}>
                            <img src={'/img/mobile.png'} />
                            电话:
                        </div>
                        <div className={styles.info_wrapper}>{ele.mobile}</div>
                    </div>
                </div>;
            })
        }
    </div>;
};
