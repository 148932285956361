// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js!../../../../../../node_modules/video-react/dist/video-react.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3djN7pEMol0MHHFWUhMVjw\\=\\= .ant-modal-content {\n  position: relative;\n  background-color: #00000000 !important;\n  border: 0;\n  border-radius: 4px;\n  background-clip: padding-box;\n  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;\n}\n._3djN7pEMol0MHHFWUhMVjw\\=\\= .ant-modal-body {\n  padding: 0 !important;\n  font-size: 0 !important;\n  line-height: 1 !important;\n}\n", "",{"version":3,"sources":["webpack://src/pages/pc/home/components/swiper/index.module.less"],"names":[],"mappings":"AAEA;EAEI,kBAAA;EACA,sCAAA;EACA,SAAA;EACA,kBAAA;EACA,4BAAA;EACA,6CAAA;AADJ;AANA;EAUI,qBAAA;EACA,uBAAA;EACA,yBAAA;AADJ","sourcesContent":["@import \"~video-react/dist/video-react.css\";\n\n.web {\n  :global(.ant-modal-content) {\n    position: relative;\n    background-color: #00000000 !important;\n    border: 0;\n    border-radius: 4px;\n    background-clip: padding-box;\n    box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;\n  }\n  :global(.ant-modal-body) {\n    padding: 0 !important;\n    font-size: 0 !important;\n    line-height: 1 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"web": "_3djN7pEMol0MHHFWUhMVjw=="
};
export default ___CSS_LOADER_EXPORT___;
