import React, {createRef, useEffect, useReducer} from 'react'
import SwiperCore, {Navigation, Pagination, Scrollbar, A11y, Mousewheel} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react'
// 引入 Swiper 样式
import styles from '../../index.module.less'
import ScrollReveal from 'scrollreveal'
import {useHistory} from 'react-router'
import {Button, Modal} from "antd";
import {Player} from 'video-react';
import style from './index.module.less'

require('swiper/swiper.less')
require('swiper/components/scrollbar/scrollbar.less')
require('swiper/components/pagination/pagination.less')
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Mousewheel])
const SwiperC = () => {
    const history = useHistory()
    const swiperRef = createRef()
    const playerRef = createRef()
    const initialState = {
        visible: false,
    };
    const reducer = (state, action) => {
        return {
            ...state,
            ...action,
        };
    };
    const [state, setState] = useReducer(reducer, initialState);
    const {visible} = state;
    let swiperObj = null

    useEffect(() => {
        let title = {
            distance: '100px',
            origin: 'bottom',
            reset: true,
        }
        ScrollReveal().reveal(`.title0`, title)
        let subTitle = {
            distance: '100px',
            origin: 'bottom',
            reset: true,
            delay: 100
        }
        ScrollReveal().reveal(`.sub_title0`, subTitle)
    }, [])

    useEffect(() => {
        if (playerRef.current) {
            document.getElementsByClassName("video-react-big-play-button")[0].style.display = "none";
            playerRef.current.video.play()
        }
    }, [playerRef])

    const datas = [
        {
            title: <div>
                <div>神州顶联<br/>校园数据智能践行者</div>
            </div>,
            desc: <div>智慧校园解决方案与投建运营服务商</div>,
            desc1: <div>以数据驱动校园业务重构，极大提升师生应用和服务体验</div>,
            img: '/img/banner(1).jpg',
            to: '/about'
        }, {
            title: <div>
                <div>智慧后勤<br/>解决方案</div>
            </div>,
            desc: <div>基于智能终端的多场景一卡通行产品</div>,
            desc1: <div>融合各类智能终端设备和应用场景，以“管理＋服务+数据+创新”的设计思想<br/>驱动后勤业务流程重构及管理转段升级</div>,
            img: '/img/banner(2).jpg',
            type: 'card',
            to: '/solutions/card'
        }, {
            title: <div>
                <div>智慧学工<br/>解决方案</div>
            </div>,
            desc: <div>面向精细化学生管理的全业务应用</div>,
            desc1: <div>以业务创新融合、精细化管理为目标，结合跨场景实时数据<br/>实现跨部门、跨系统、跨应用的创新型学工平台建设</div>,
            img: '/img/banner(3).jpg',
            type: 'learn',
            to: '/solutions/learn'
        },
    ]

    return <div className={styles.banner_wrapper}>
        <Swiper
            autoplay
            ref={swiperRef}
            slide={'fade'}
            pagination={{clickable: true}}
            onSwiper={swiper => (swiperObj = swiper)}
        >
            <div className={styles.swiper_wrapper}>
                {datas.map((ele, index) => (
                    <SwiperSlide style={{width: '100% !important'}} key={`swiper${index}`}>
                        <img src={ele.img}/>
                        <div
                            className={`${index === 1 ? styles.info_wrapper : styles.info_wrapper2}`}>
                            <div className={styles.title}>{ele.title}</div>
                            <div className={styles.sub_title}>{ele.desc}</div>
                            <div className={styles.sub_title1}>{ele.desc1}</div>
                            <div className={styles.title1}>{ele.title1}</div>
                            <div className={styles.sub_title2}>{ele.desc2}</div>
                            {index === 0 || index === 1 || index === 2 ?
                                <Button type="primary" shape="round"
                                        style={{marginTop: 50}} className={styles.ButConfirm}
                                        onClick={() => {
                                            history.replace({
                                                pathname: `${ele.to}`,
                                                state: {type: ele.type},
                                            });
                                        }}
                                >查看更多</Button> : null
                            }
                        </div>
                    </SwiperSlide>
                ))}
            </div>
        </Swiper>
        <Modal
            visible={visible}
            footer={false}
            width={1000}
            closable={false}
            onCancel={() => {
                playerRef.current.video.pause()
                setState({visible: false})
            }}
            wrapClassName={style.web}//对话框外部的类名，主要是用来修改这个modal的样式的
        >
            <Player
                autoPlay
                ref={playerRef}
                src="https://iot-statics.oss-cn-hangzhou.aliyuncs.com/icons/mid-year_meeting.mp4">
            </Player>
        </Modal>
    </div>
}

export default SwiperC
