import React, { createRef, useEffect, useReducer, useState } from 'react'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import styles from '../index.module.less'
import ScrollReveal from 'scrollreveal'
import { PlayCircleOutlined } from '@ant-design/icons'
import VideoPlayer from '../../../../components/videoPlayer'

require('swiper/swiper.less')
require('swiper/components/scrollbar/scrollbar.less')
require('swiper/components/pagination/pagination.less')

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Mousewheel])

const SwiperS = () => {
    const swiperRef = createRef()
    const playerRef = createRef()

    const initialState = {
        visible: false,
    };
    const reducer = (state, action) => {
        return {
            ...state,
            ...action,
        };
    };
    const [state, setState] = useReducer(reducer, initialState);
    const [visible, setVisible] = useState(false);

    let swiperObj = null
    const cancel = () => {
        setVisible(false);
    }
    useEffect(() => {
        let title = {
            distance: '100px',
            origin: 'bottom',
            reset: true,
        }
        ScrollReveal().reveal(`.title0`, title)
        let subTitle = {
            distance: '100px',
            origin: 'bottom',
            reset: true,
            delay: 100
        }
        ScrollReveal().reveal(`.sub_title0`, subTitle)
    }, [])

    useEffect(() => {
        if (playerRef.current) {
            document.getElementsByClassName("video-react-big-play-button")[0].style.display = "none";
            playerRef.current.video.play()
        }
    }, [playerRef])

    const datas = [
        {
            title1: <div>神州顶联</div>,
            desc1: <div>社会招聘面试指南</div>,
            img: '/img/join03.png',
        }, {
            title: <div>长风破浪，与我们一起共建未来</div>,
            desc: <div>我们心中有未来智慧校园的蓝图，诚邀你与我们共同实现</div>,
            img: '/img/join01.png',
        }]

    return <div className={styles.banner_wrapper}>
        <Swiper
            ref={swiperRef}
            slide={'fade'}
            pagination={{ clickable: true }}
            onSwiper={swiper => (swiperObj = swiper)}
        >
            <div className={styles.swiperS_wrapper}>
                {
                    datas.map((ele, index) => (
                        <SwiperSlide style={{ width: '100% !important' }} key={`swiper${index}`}>
                            <img src={ele.img} />
                            <div className={styles.banner_text}>
                                <div className={styles.title_wrapper1}>
                                    <div className={styles.title}>{ele.title}</div>
                                    <div className={styles.desc}>{ele.desc}</div>
                                </div>
                                <div className={styles.title_wrapper2}>
                                    <div className={styles.title1}>{ele.title1}</div>
                                    <div className={styles.desc1}>{ele.desc1}</div>
                                </div>
                                {index === 0 ? <PlayCircleOutlined
                                    className={styles.play}
                                    onClick={(e) => {
                                        setVisible(
                                            true
                                        )
                                    }}
                                />
                                    : null}
                            </div>
                        </SwiperSlide>
                    ))
                }
            </div>
        </Swiper>
        <VideoPlayer visible={visible} cancel={cancel} urlVideo={'https://iot-statics.oss-cn-hangzhou.aliyuncs.com/icons/interview_process.mp4'}></VideoPlayer>
    </div>
}

export default SwiperS
