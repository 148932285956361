import React, {useEffect, useState} from 'react';
import styles from './index.module.less';
import Header from '../../../components/header';
import Bottom from '../../../components/contact';
import Reserve from '../../../components/reserve';
import BasicLayout from '../../../layout/basicLayout';
import PageTop from '../../../components/pageTop';
import Solution_Module from '../../../components/module/solution';
import {SOLUTIONS} from '../../../contant';
import {useHistory} from 'react-router-dom';
import Module from "../../../components/module";

const Solutions = (props) => {
    const [solution, setSolution] = useState({
        type: '',
        banner: '',
        title: '',
        title_long: '',
        description: '',
        fanganzhengtijiagou: '',
        shujukeshihuadaping: [],
        fanganjiazhiliangdian: [],
        yingjianchanpintushi: [],
    });
    const [other, setOther] = useState([]);
    const history = useHistory();
    useEffect(() => {
        initData()
    }, []);
    const initData = () => {
        let state = props.location.pathname.split('/')[2]
        let type = props.location.state ? props.location.state.type : state
        const item = SOLUTIONS.find(ele => ele.type === type);
        setSolution(item);
        const otherArr = SOLUTIONS.filter(ele => ele.type !== type);
        const arrNew = [];
        for (let i = 0; i < 4; i++) {
            const _num = Math.floor(Math.random() * otherArr.length);
            arrNew.push(...otherArr.splice(_num, 1));
        }
        setOther(arrNew)
    }

    useEffect(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0
        initData()
    }, [props.location.state])

    return <BasicLayout>
        <Header fixed/>
        <div className={'content_wrapper'}>

            <PageTop bg={solution.banner}
                     title={solution.title_long}
                     description={solution.description}
                     isSolution={true}
            />
            <Solution_Module title={'方案整体架构'} bg={'/img/solution/common/module_bg/01.png'} isFirst={true}
                             style={{padding: '50px 18%'}}>
                <img className={styles.fanganzhengtijiagou_img} src={solution.fanganzhengtijiagou}/>
            </Solution_Module>
            {solution.type === 'sell' ?
                <Solution_Module title={'硬件产品图示'} bg={'/img/solution/common/module_bg/02.png'}>
                    <div className={styles.product_img_wrapper}>
                        <img style={{marginRight: 20}} src={solution.yingjianchanpintushi[0]}/>
                        <img src={solution.yingjianchanpintushi[1]}/>
                    </div>
                </Solution_Module> : solution.type === 'portal' ?
                    <>
                        <Solution_Module title={'校园门户WEB端'} bg={'/img/solution/common/module_bg/02.png'}>
                            <img className={styles.fanganzhengtijiagou_img} src={solution.xiaoyuanmenhu_web}/>
                        </Solution_Module>
                        <Solution_Module title={'校园门户APP端'} bg={'/img/solution/common/module_bg/04.png'}>
                            <img className={styles.fanganzhengtijiagou_img} src={solution.xiaoyuanmenhu_app}/>
                        </Solution_Module>
                    </>
                    :
                    <Solution_Module title={'数据可视化大屏'} bg={'/img/solution/common/module_bg/02.png'}>
                        {solution.shujukeshihuadaping.map(e => {
                            return <div className={styles.data_screen_wrapper}>
                                <div>
                                    <div className={styles.title}>{e.title}</div>
                                    <div className={styles.description}>{e.description}</div>
                                    <div>
                                <span className={styles.button} onClick={() => {
                                    history.replace({
                                        pathname: `/about`,
                                    });
                                }}>立即咨询</span>
                                    </div>
                                </div>
                                <img className={styles.data_screen_img} src={e.img}/>
                            </div>
                        })}
                    </Solution_Module>}
            <Solution_Module title={'方案价值亮点'} bg={'/img/solution/common/module_bg/03.png'}>
                <div className={styles.project_value_wrapper}>
                    <div className={styles.content}>
                        {solution.fanganjiazhiliangdian.map((e, index) => {
                            return <span className={styles.item_wrapper}>
                                <div className={styles.item}>
                                    <div className={styles.img_wrapper}>
                                        <img className={styles.img}
                                             src={`/img/solution/common/fanganjiazhiliangdian_icon/icon_0${index + 1}.png`}/>
                                    <div className={styles.line}/>
                                    </div>
                                    <div>
                                        <div className={styles.title}>{e.title}</div>
                                        <div className={styles.description}>{e.description}</div>
                                    </div>
                                </div>
                            </span>
                        })}
                    </div>
                </div>
            </Solution_Module>


            <Module title={'相关方案'} eng={'More solutions'} bg={''}>
                <div className={styles.other_solutions_wrapper}>
                    {<div className={styles.scene_row_wrapper}>
                        {other.map(
                            (item, index) => {
                                return <div key={`scene_item${index}`}
                                            onClick={() => {
                                                props.history.replace({
                                                    pathname: `${item.to}`,
                                                    state: {type: item.type},
                                                });
                                            }}
                                            className={`${styles.scene_item} scene_item${index}`}>
                                    <div className={styles.mask}/>
                                    <img className={'img_bg'}
                                         src={item.banner_M}/>
                                    <div className={styles.info_wrapper}>
                                        <div className={styles.icon_wrapper}>
                                            <div>{item.title}</div>
                                        </div>
                                        <div className={styles.intro}>{item.description}</div>
                                    </div>
                                </div>;
                            })}
                    </div>}
                </div>
            </Module>
        </div>
        <Bottom/>
        <Reserve/>
    </BasicLayout>;
};

export default Solutions;
