import React, {useEffect, useState} from 'react';
import styles from './index.module.less';
import PageTop_M from '../../../comp-mobile/pageTop';
import Header from '../../../comp-mobile/header';
import {drawerData_Temporary} from '../../../contant';
import Module from '../../../comp-mobile/module';
import Footer from '../../../comp-mobile/footer';

const Product_M = (props) => {
    document.title = '产品'
    const [type, setType] = useState('water_saving')
    const current_product = drawerData_Temporary[1].children.find(item => item.type === type)
    const [iotIndex, setIotIndex] = useState(0)
    const [ictIndex, setIctIndex] = useState(0)

    useEffect(() => {
        let index = props.match.params.type
        if (index) {
            index = parseInt(index)
            setTimeout(() => {
                if (index < 10) {
                    let anchorElement = document.getElementById('Iot')
                    if (anchorElement) {
                        anchorElement.scrollIntoView()
                    }
                    setIotIndex(index)
                } else {
                    let anchorElement = document.getElementById('Ict')
                    if (anchorElement) {
                        anchorElement.scrollIntoView()
                    }
                    setIctIndex(index - 10)
                }
            }, 50)
        }
    }, []);

    return <div className={styles.container_wrapper}>
        <Header/>
        <PageTop_M bg={'/img/product_bg_M.png'}
                   title={<div>聚焦体验，打造校园服务新格局</div>}/>
        <div className={styles.content_wrapper}>
            <Module id={'Intelligent'} title={'智能硬件终端'} eng={'Intelligent hardware'}>
                <div className={styles.IOT_tab_wrapper}>
                    {drawerData_Temporary[1].children.map((ele, index) => {
                        return <div onClick={() => {
                            setType(ele.type)
                        }} className={`${styles.IOT_tab_item} ${type === ele.type ? styles.selected : ''}`
                        } key={``}>{ele.title}</div>
                    })}
                </div>
                <div className={styles.smart_life_wrapper}>
                    {current_product.children.map(ele => {
                        return <div key={ele.title} className={styles.smart_life_item_wrapper}>
                            <img src={ele.img}/>
                            <div className={styles.product_info_wrapper}>
                                <div className={styles.title}>{ele.title}</div>
                            </div>
                            <div className={styles.detail_btn} onClick={() => {
                                props.history.push({
                                    pathname: '/productDetail',
                                    query: {title: ele.title, type: type}
                                })
                            }}>了解详情
                            </div>
                        </div>
                    })}
                </div>
            </Module>
            <Footer/>
        </div>
    </div>;
};
export default Product_M;
