import React, {useEffect, useState} from 'react';
import Header from '../../../components/header';
import Bottom from '../../../components/contact';
import Reserve from '../../../components/reserve';
import BasicLayout from '../../../layout/basicLayout';
import Style from './index.module.less';
import {
    CHANNEL_PRODUCTS_TEMPORARY, CLASSROOM_PRODUCTS_TEMPORARY,APP,
    CONSUMPTION_LIFE_PRODUCTS_Temporary, LOCK_PRODUCTS_TEMPORARY,
    PARK_PRODUCTS_TEMPORARY,
    SCHOOLNET_LIFE_PRODUCTS_Temporary,
    SHARE_PRODUCTS_Temporary,
    WATER_SAVING_PRODUCTS_Temporary
} from '../../../contant';
import Bread from '../../../components/bread';
import PageTop from '../../../components/pageTop';
import Video from "../../../components/video";
const ProductDetail = (props) => {
    const [product, setProduct] = useState({detail: {}, tables: []})
    const {title, type} = props.location.query
    useEffect(() => {
        switch (type) {
            case 'water_saving':
                setProduct(WATER_SAVING_PRODUCTS_Temporary.find(ele => ele.title === title))
                break;
            case 'shared':
                setProduct(SHARE_PRODUCTS_Temporary.find(ele => ele.title === title))
                break;
            case 'consumer':
                setProduct(CONSUMPTION_LIFE_PRODUCTS_Temporary.find(ele => ele.title === title))
                break;
            case 'network':
                setProduct(SCHOOLNET_LIFE_PRODUCTS_Temporary.find(ele => ele.title === title))
                break;
            case 'channel':
                setProduct(CHANNEL_PRODUCTS_TEMPORARY.find(ele => ele.title === title))
                break;
            case 'parking':
                setProduct(PARK_PRODUCTS_TEMPORARY.find(ele => ele.title === title))
                break;
            case 'door_lock':
                setProduct(LOCK_PRODUCTS_TEMPORARY.find(ele => ele.title === title))
                break;
            case 'classroom':
                setProduct(CLASSROOM_PRODUCTS_TEMPORARY.find(ele => ele.title === title))
                break;
            case 'app':
                setProduct(APP.find(ele => ele.title === title))
                break;

        }

    })
    return <BasicLayout>
        <Header fixed/>
        { product.title === '智能锁' ?
            <Video title={'神州顶联-智能门锁'}
                   desc={'邀您开启智慧校园新生活'}
                   img={'/img/door_lock_banner.png'}
                   address={'https://iot-statics.oss-cn-hangzhou.aliyuncs.com/icons/doorLock30s.mp4'}
            />
            : <PageTop bg={'/img/banner_pro_detail.png'} title={'聚焦体验，打造校园服务新格局'}/>  }
        <Bread breads={[{name: '首页', href: '#/home'}, {name: '产品', href: '#/product'}, {name: '产品详情'},]}/>
        <div className={Style.product_detail_wrapper}>
            <div className={Style.title}>{product.title}</div>
            <div className={Style.info_wrapper}>
                <div className={Style.info_intro}>
                    {product.detail.scene !== '' ?  <>
                        < div className={Style.title}>适用场景</div>
                        <div className={Style.desc}>{product.detail.scene}</div></>
                        : null
                }

                    <div className={Style.title}>产品简介</div>
                    <div className={Style.desc}>{product.detail.intro}</div>

                    <div className={Style.title}>产品特色</div>
                    <div className={Style.desc}>{product.detail.tese}</div>
                </div>
                <div>
                    <img src={product.img} className={Style.info_img}/>
                    <div style={{textAlign:"center"}}>{product.version}</div>
                </div>
            </div>
        </div>
        <Bottom/>
        <Reserve/>
    </BasicLayout>
};
export default ProductDetail;
