import React, {useEffect, useState, createRef} from 'react'
import styles from './index.module.less'
import ScrollReveal from "scrollreveal";
import {useHistory} from "react-router-dom";
import Header from "../../../components/header";
import BasicLayout from "../../../layout/basicLayout";
import Bottom from "../../../components/contact";
import Reserve from "../../../components/reserve";
import PageTop from "../../../components/pageTop";
import Module from "../../../components/module";
import Style from '../../../layout/basicLayout.moudle.less'
import { platforms } from '../../../contant'

const Platform = () => {

    const history = useHistory()
    useEffect(() => {


    }, [])

    return <BasicLayout>
        <Header fixed/>
        <div className={Style.content_wrapper}>
            <PageTop bg={'/img/platform_bg.png'} title={'云联,让每一个数据都为您创造价值'}/>

            <Module title={'云联平台产品架构'}  eng={'Product architecture of Yunlian platform'}>
                <div className={styles.yunlian_desc}>
                    神州顶联驱动创新,自主研发了基于校园场景内的云联校园运营服务超级管理平台。该平台集11款子系统于一体,支撑校园场景内ICT（信息通讯）、IOT(物联网)及教学培训服务 类项目运营,为学校后勤管理、教育教学质量提供保障,为学生提供校园生活场景下高质量、现代化的服务,为项目运营商提供线上项目管理、数据智能决策等服务。
                </div>

                <div className={styles.platform_grid}>
                    {platforms(true).map((ele, index) => {
                        return <div key={index}
                                    className={`${styles.grid_item} ${index < 2 ? styles.line_two : (index > 2 && index < 10) ? styles.line_seven : styles.line_one}`}>
                            <img src={ele.img}/>
                            <div className={styles.mask}/>
                            <div className={styles.title}>
                                <div>
                                    {ele.title}
                                </div>
                                <img onClick={() => {
                                    history.push(`/platformDetail/${index}`)
                                }} src={'/img/jinru.png'} className={styles.icon}/>
                            </div>
                        </div>
                    })}
                </div>
            </Module>
        </div>
        <Bottom/>
        <Reserve/>
    </BasicLayout>
}
export default Platform